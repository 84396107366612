import React, { useEffect, useState } from "react";
import axios from "axios";
import { IoIosCloseCircleOutline } from "react-icons/io";
const AddProduct = ({ onClose }) => {
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [name, setName] = useState("");

  const [subCategories, setSubcategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [productId, setProductId] = useState("");
  const [discount, setDiscount] = useState("");

  const [price, setPrice] = useState("");
  const [stock, setStock] = useState("");
  const [size, setSize] = useState([{ sizevalue: "", price: "" }]);
  const [description, setDescription] = useState("");
  const [color, setColor] = useState([{ color: "", code: "" }]);
  const [images, setImage] = useState([]);
  const [imagesPreview, setImagePreview] = useState([]);

  // Fetch categories from the backend
  const getAllCategories = () => {
    const token = localStorage.getItem("token");

    axios
      .get("https://byportecommerce-ufza.onrender.com/api/v1/get-category", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setCategories(res.data.categories);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect to fetch categories when component mounts
  useEffect(() => {
    getAllCategories();
  }, []);

  // Function to handle dynamic input changes for size
  const handleSizeChange = (e, index) => {
    const { name, value } = e.target;
    setSize((prevSize) => {
      const newSize = [...prevSize];
      newSize[index] = { ...newSize[index], [name]: value };
      return newSize;
    });
  };

  // Function to add a new size input field
  const addSize = () => {
    setSize([...size, { sizevalue: "", price: "" }]);
  };

  // Function to remove a size input field
  const removeSize = (index) => {
    setSize((prevSize) => {
      const newSize = [...prevSize];
      newSize.splice(index, 1);
      return newSize;
    });
  };

  // Function to handle dynamic input changes for color
  const handleColorChange = (e, index) => {
    const { name, value } = e.target;
    setColor((prevSize) => {
      const newSize = [...prevSize];
      newSize[index] = { ...newSize[index], [name]: value };
      return newSize;
    });
  };

  // Function to add a new color input field
  const addColor = () => {
    setColor([...color, { color: "", code: "" }]);
  };

  // Function to remove a color input field
  const removeColor = (index) => {
    setColor((prevSize) => {
      const newSize = [...prevSize];
      newSize.splice(index, 1);
      return newSize;
    });
  };

  // Function to handle image uploads
  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    setImage([]);
    setImagePreview([]);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setImage((old) => [...old, reader.result]);
          setImagePreview((old) => [...old, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  // Function to remove an image from the list
  const removeImage = (index) => {
    const list = [...images];
    list.splice(index, 1);
    setImage(list);
  };

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Construct your payload for API submission
    const formData = new FormData();
    formData.append("productId", productId);
    formData.append("name", name);
    formData.append("price", price);
    formData.append("discount", discount);

    formData.append("stock", stock);
    formData.append("description", description);
    formData.append("category", category);
    formData.append("subCategories", selectedSubCategory);

    // Append sizes data

    // Append colors data

    // Append images data
    images.forEach((image, index) => {
      formData.append("images", image);
    });
    formData.append("size", JSON.stringify(size));
    formData.append("variant", JSON.stringify(color));

    try {
      // Make API call to submit formData
      const response = await axios.post(
        "https://byportecommerce-ufza.onrender.com/api/v1/admin/product/new",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      onClose();
      console.log("Product creation successful:", response.data);
      // Handle success scenario (e.g., show success message)
    } catch (error) {
      console.error("Error creating product:", error);
      // Handle error scenario (e.g., show error message)
    }
  };
  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    setCategory(selectedCategory);

    // Find the selected category object
    const selectedCategoryObject = categories.find(
      (cat) => cat.name === selectedCategory
    );

    // Set subcategories based on the selected category
    if (selectedCategoryObject) {
      setSubcategories(selectedCategoryObject.subcategories);
      setSelectedSubCategory(""); // Clear selected subcategory when category changes
    } else {
      setSubcategories([]);
      setSelectedSubCategory("");
    }
  };

  // Function to handle subcategory selection
  const handleSubCategoryChange = (e) => {
    setSelectedSubCategory(e.target.value);
  };

  return (
    <div
      className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm"
      style={{ margin: "1rem" }}
    >
      <div style={{position: "relative",
    display: "flex",
    justifyContent: "end",
    cursor: "pointer"}}>
        <IoIosCloseCircleOutline size={20} onClick={onClose} />
      </div>
      <form className="space-y-6" onSubmit={handleSubmit}>
        {/* Product Name */}
        <div>
          <label
            htmlFor="productName"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Product Id
          </label>
          <div className="mt-2">
            <input
              id="productName"
              name="productId"
              type="text"
              value={productId}
              onChange={(e) => setProductId(e.target.value)}
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="productName"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Product Name
          </label>
          <div className="mt-2">
            <input
              id="productName"
              name="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="productName"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Discount
          </label>
          <div className="mt-2">
            <input
              id="productName"
              name="discount"
              type="text"
              value={discount}
              onChange={(e) => setDiscount(e.target.value)}
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/* Price */}
        <div>
          <label
            htmlFor="productPrice"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Price
          </label>
          <div className="mt-2">
            <input
              id="productPrice"
              name="price"
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        {/* Stock */}
        <div>
          <label
            htmlFor="productStock"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Stock
          </label>
          <div className="mt-2">
            <input
              id="productStock"
              name="stock"
              type="number"
              value={stock}
              onChange={(e) => setStock(e.target.value)}
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        {/* Description */}
        <div>
          <label
            htmlFor="productDescription"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Description
          </label>
          <div className="mt-2">
            <textarea
              id="productDescription"
              name="description"
              rows="20"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="category"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Category
          </label>
          <div className="mt-2">
            <select
              id="category"
              name="category"
              value={category}
              onChange={handleCategoryChange}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="">Select a category</option>
              {categories.map((cat) => (
                <option key={cat._id} value={cat.name}>
                  {cat.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Subcategory Select */}
        {subCategories.length > 0 && (
          <div className="mt-4">
            <label
              htmlFor="subCategory"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Subcategory
            </label>
            <div className="mt-2">
              <select
                id="subCategory"
                name="subCategories"
                value={selectedSubCategory}
                onChange={handleSubCategoryChange}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="">Select a subcategory</option>
                {subCategories.map((subCat, index) => (
                  <option key={index} value={subCat.name}>
                    {subCat.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
        {/* Size */}
        {size.map((item, index) => (
          <div key={index}>
            <label
              htmlFor={`size-${index}`}
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Size {index + 1}
            </label>
            <div className="mt-2 space-y-2">
              <input
                name="sizevalue"
                type="text"
                placeholder="Size"
                value={item.sizevalue}
                onChange={(e) => handleSizeChange(e, index)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <input
                name="price"
                type="text"
                placeholder="Price"
                value={item.price}
                onChange={(e) => handleSizeChange(e, index)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />

              <button
                type="button"
                onClick={() => removeSize(index)}
                className="ml-2 px-2 py-1.5 rounded-md bg-red-600 text-white text-xs font-semibold leading-4 shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:ring-2 focus-visible:ring-red-600 focus-visible:ring-offset-2"
              >
                Remove
              </button>
            </div>
          </div>
        ))}
        <div>
          <button
            type="button"
            onClick={addSize}
            className="px-3 py-1.5 rounded-md bg-green-600 text-white text-sm font-semibold leading-6 shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2 focus-visible:ring-offset-green-200"
          >
            Add Size
          </button>
        </div>

        {/* Color */}
        {color.map((item, index) => (
          <div key={index}>
            <label
              htmlFor={`color-${index}`}
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Color {index + 1}
            </label>
            <div className="mt-2 space-y-2">
              <input
                id={`color-${index}`}
                name="color"
                type="text"
                placeholder="Color"
                value={item.color}
                onChange={(e) => handleColorChange(e, index)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <input
                id={`code-${index}`}
                name="code"
                type="text"
                placeholder="Color Code"
                value={item.code}
                onChange={(e) => handleColorChange(e, index)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />

              <button
                type="button"
                onClick={() => removeColor(index)}
                className="ml-2 px-2 py-1.5 rounded-md bg-red-600 text-white text-xs font-semibold leading-4 shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:ring-2 focus-visible:ring-red-600 focus-visible:ring-offset-2"
              >
                Remove
              </button>
            </div>
          </div>
        ))}
        <div>
          <button
            type="button"
            onClick={addColor}
            className="px-3 py-1.5 rounded-md bg-blue-600 text-white text-sm font-semibold leading-6 shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-200"
          >
            Add Color
          </button>
        </div>

        {/* Images */}
        <div>
          <label
            htmlFor="productImages"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Images
          </label>
          <div className="mt-2 space-y-2">
            <input
              id="productImages"
              name="images"
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              multiple
            />
            <div
              style={{ marginTop: "10px", display: "flex", overflow: "auto" }}
            >
              {imagesPreview.map((file, index) => (
                <div key={index} className="flex items-center space-x-2">
                  <img
                    src={file}
                    alt="oiuu"
                    style={{
                      width: "auto",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <button
                    type="button"
                    onClick={() => removeImage(index)}
                    className="text-red-600 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-red-200"
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <div>
          <button
            type="submit"
            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-200"
          >
            Create Product
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddProduct;
