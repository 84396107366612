import axios from "axios";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ReviewCard = ({ prodId }) => {
  const [products, setProducts] = useState([]);

  const getAllProducts = () => {
    axios
      .get(
        `https://byportecommerce-ufza.onrender.com/api/v1/reviews?id=${prodId}`
      )
      .then((res) => {
        setProducts(res.data.reviews);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllProducts();
  }, [prodId]);

  const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <button
        className={`${className} bg-black-200 text-gray-600 rounded-full w-10 h-10 flex items-center justify-center`}
        style={{ ...style, right: "-30px", background: "black" }}
        onClick={onClick}
      >
        &lt;
      </button>
    );
  };

  const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <button
        className={`${className} bg-black-200 text-gray-600 rounded-full w-10 h-10 flex items-center justify-center`}
        style={{ ...style, right: "-30px", background: "black" }}
        onClick={onClick}
      >
        &gt;
      </button>
    );
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <section className="text-gray-600 body-font" style={{ margin: "1rem" }}>
      <div className="container px-5 py-24 mx-auto">
        <span className="mb-3 inline-block text-sm font-semibold text-gray-500 md:text-base">
          Review
        </span>
        <Slider {...settings}>
          {products.map((product, index) => (
            <div key={index} className="p-2">
              <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                <img
                  alt="product"
                  className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
                  src={product.avatar ? product.avatar:"https://dummyimage.com/80x80"} // Use actual product image URL
                />
                <div className="flex-grow">
                  <h2 className="text-gray-900 title-font font-medium">
                    {product.name} {/* Use actual product name */}
                  </h2>
                  <p className="text-gray-500">{product.comment}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default ReviewCard;
