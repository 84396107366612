import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { FaRupeeSign, FaShareAlt, FaCartPlus, FaEye } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const AllProductCard = () => {
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]);

  const getAllProducts = () => {
    axios
      .get("https://byportecommerce-ufza.onrender.com/api/v1/products")
      .then((res) => {
        setProducts(res.data.products);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllProducts();
    const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
    setCart(storedCart);
  }, []);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  const handleWhatsAppShare = (productName, productId, e) => {
    e.preventDefault();
    e.stopPropagation();
    const shareText = `Check out this product: ${productName} - https://byport.co/product-detail/${productId}`;
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(shareText)}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleAddToCart = (product) => {
    const existingIndex = cart.findIndex((item) => item._id === product._id);

    if (existingIndex !== -1) {
      const updatedCart = [...cart];
      updatedCart[existingIndex].quantity++;
      setCart(updatedCart);
      toast.success("Product is added to cart");
    } else {
      const updatedCart = [...cart, { ...product, quantity: 1 }];
      setCart(updatedCart);
      toast.success("Product is added to cart");
    }
  };
  const navigate = useNavigate();
  const handleMove = (id) => {
    navigate(`/product-detail/${id}`);
  };
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    } else {
      return text;
    }
  };
  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Number of products shown at once
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const cardStyle = {
    marginRight: "20px", // Adjust as per your spacing needs
  };
  return (
    <>
      <ToastContainer />

      <div className="bg-white">
        <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
          <h2 class="text-2xl font-bold tracking-tight text-gray-900">
            New Collection
          </h2>
          <Slider {...sliderSettings}>
            {products.map((product) => {
              const discountedPrice = product.discount
                ? product.price - product.price * (product.discount / 100)
                : product.price;

              return (
                <div
                  style={{
                    gap: "20px",
                    position: "relative",
                    display: "flex",
                  }}
                >
                  <div
                    key={product._id}
                    className="group relative shadow-lg"
                    style={cardStyle}
                  >
                    <div className="w-full h-80 overflow-hidden rounded-md bg-gray-200 relative">
                      {product.discount && (
                        <div
                          className="absolute top-2 right-2 bg-gold text-white text-xs font-bold py-1 px-2 rounded-md"
                          style={{ backgroundColor: "#ffd700" }}
                        >
                          {product.discount}% OFF
                        </div>
                      )}
                      <div
                        className="absolute top-2 left-2 text-black text-xs font-bold py-1 px-2 rounded-md cursor-pointer"
                        onClick={(e) =>
                          handleWhatsAppShare(product.name, product._id, e)
                        }
                      >
                        <FaShareAlt size={20} />
                      </div>
                      <img
                        src={product.images[0]?.url}
                        alt="Product"
                        className="object-cover object-center"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                    <div className="m-2">
                      <h3 className="text-sm text-gray-700 mb-2">
                        <p
                          onClick={() => handleMove(product?._id)}
                          className="cursor-pointer text-ellipsis"
                          title={product.name} // Optional: add title attribute for full text on hover
                        >
                          {truncateText(product.name, 25)}
                        </p>
                      </h3>
                      <div className="flex items-center mb-6 md:mb-10">
                        <div
                          className="flex h-7 items-center rounded-full bg-indigo-500 px-2 text-white"
                          style={{ backgroundColor: "#FFD700" }} // Goldish color
                        >
                          <span className="text-xs mr-1">
                            {product.ratings.toFixed(2)}
                          </span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                          </svg>
                        </div>
                        <span className="text-xs text-gray-500 ml-2">
                          {product.reviews.length}ratings
                        </span>
                      </div>
                      <div
                        className="flex justify-between items-center"
                        style={{ bottom: "25px", position: "relative" }}
                      >
                        <div className="text-sm font-medium text-gray-900">
                          {product.discount && (
                            <div className="text-gray-500 line-through flex items-center">
                              <FaRupeeSign size={10} className="mr-1" />
                              {product.price} {product.discount} % off
                            </div>
                          )}
                          <div className="flex items-center">
                            <FaRupeeSign size={10} className="mr-1" />
                            {discountedPrice.toFixed(2)}
                          </div>
                        </div>
                        <p>
                          <button
                            className="flex items-center bg-gray-800 text-white px-3 py-1 rounded-md hover:bg-gray-700"
                            onClick={() => handleMove(product?._id)}
                          >
                            <FaEye className="mr-1" />
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default AllProductCard;
