import React from "react";
import "./App.css";
import Index from "./Page";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ProductDetail from "./Page/productOne";
import Cart from "./component/cart";
import PageNotFound from "./component/notfound";
import CheckOut from "./component/checkout";
import UserProfile from "./component/userProfile";
import SignIn from "./component/signin";
import SignUp from "./component/Signup";
import Admin from "./Page/Admin";
import ProductAdminLits from "./component/AdminPortal/productAdminList";
import Dashboard from "./component/AdminPortal/dashboard";
import DataCategories from "./component/AdminPortal/dataCategories";
import UserList from "./component/AdminPortal/userList";
import GroceriesCard from "./component/groceries";
import DryFish from "./component/dryfish";
import DryFruitsCard from "./component/dryfruits";
import MasalaCard from "./component/masala";
import SweetCard from "./component/sweets";
import GroceriesPage from "./Page/Groceries";
import DryFruitsPage from "./Page/dryfruitsPage";
import SweetPage from "./Page/sweetPage";
import MasalaPage from "./Page/masalaPage";
import DryFishPage from "./Page/dryFishPage";
import CategoriesIcon from "./component/categoriesIcon";
import OrderAdminList from "./component/AdminPortal/orderList";
import OrderDetail from "./component/AdminPortal/orderdetail";
import OrderDetailPage from "./Page/orderdetailPage";
import OrderHistoryPage from "./Page/orderHistoryPage";
import SettingsPage from "./Page/settingPage";
import ForgotPassword from "./component/forgotpassword";
import ChangePassword from "./component/change-password";
import BannerList from "./component/AdminPortal/banner-lisr";
import Terms from "./component/term";
import Privacy from "./component/privacy";
import Refund from "./component/refund";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route exact path="/" element={<Index />} />{" "}
          {/* Renders Index component at / */}
          <Route exact  path="product-detail/:id" element={<ProductDetail />} />{" "}
          {/* Renders ProductDetail component at /product-detail */}
          <Route exact path="cart" element={<Cart />} />{" "}
          {/* Renders Index component at / */}
          <Route exact path="not-found" element={<PageNotFound />} />{" "}
          {/* Renders Index component at / */}
          <Route exact path="checkout" element={<CheckOut />} />{" "}
          <Route exact path="my-profile" element={<UserProfile />} />{" "}
          <Route exact path="setting" element={<SettingsPage />} />{" "}
          <Route exact path="change-password" element={<ForgotPassword />} />{" "}

          <Route exact path="login" element={<SignIn />} />{" "}
          <Route exact path="Signin" element={<SignUp />} />{" "}
          <Route exact path="admin" element={<Admin />} />{" "}
          <Route exact path="admin-product-list" element={<ProductAdminLits />} />{" "}
          <Route exact path="admin-order-list" element={<OrderAdminList />} />{" "}
          <Route exact path="order-detail/:id" element={<OrderDetailPage />} />{" "}
          <Route exact path="dashboard" element={<Dashboard />} />{" "}
          <Route exact path="order-history" element={<OrderHistoryPage />} />{" "}
          <Route exact path="reset-password" element={<ChangePassword />} />{" "}

          <Route exact path="category-list" element={<DataCategories />} />{" "}
          <Route exact path="user-list" element={<UserList />} />{" "}
          <Route exact path="banner-list" element={<BannerList />} />{" "}

          <Route exact path="groceries" element={<GroceriesPage />} />{" "}
          <Route exact path="dry-fish" element={<DryFishPage />} />{" "}
          <Route exact path="dry-fruits" element={<DryFruitsPage />} />{" "}
          <Route exact path="masala" element={<MasalaPage />} />{" "}
          <Route exact path="sweet" element={<SweetPage />} />{" "}
          <Route exact path="category-icon" element={<CategoriesIcon />} />{" "}
          <Route exact path="refund" element={<Refund />} />{" "}
          <Route exact path="privacy" element={<Privacy />} />{" "}
          <Route exact path="term" element={<Terms />} />{" "}

          {/* Renders Index component at / */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
