import React, { useEffect, useState } from "react";
import axios from "axios";
import { IoIosCloseCircleOutline } from "react-icons/io";

const EditOrder = ({ id, onClose }) => {
  const [prod, setProduct] = useState("");
  const [order, setOrder] = useState("");

  const orders = [
    {
      name: "Shipped",
    },
    {
      name: "Delivered",
    },
  ];

  // Function to handle form submission

  // Function to handle subcategory selection

  useEffect(() => {
    const getOneProduct = async (id) => {
      try {
        const response = await axios.get(
          `https://byportecommerce-ufza.onrender.com/api/v1/orders/${id}`
        );
        setOrder(response.data.order.orderStatus); // Assuming orderStatus is returned in the response
      } catch (error) {
        console.error("Error fetching order:", error);
      }
    };

    getOneProduct(id);
  }, [id]); // Include id in dependency array to fetch new order when id changes

  // Handle change in order status dropdown
  const handleCategoryChange = (e) => {
    setOrder(e.target.value); // Update order state with selected order status
  };

  // Handle form submission to update order status
  const handleSubmit = () => {
    const payload = {
      status: order, // Send only the order status in the payload
    };

    axios
      .put(
        `https://byportecommerce-ufza.onrender.com/api/v1/admin/order/${id}`,
        payload
      )
      .then((res) => {
        onClose(); // Close the modal or perform other actions on successful update
      })
      .catch((err) => {
        console.error("Error updating order:", err);
      });
  };
  return (
    <div
      className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm"
      style={{ margin: "1rem" }}
    >
      <div
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "end",
          cursor: "pointer",
        }}
      >
        <IoIosCloseCircleOutline size={20} onClick={onClose} />
      </div>
      <div className="space-y-6">
        {/* Product Name */}

        <div>
          <label
            htmlFor="category"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Category
          </label>
          <div className="mt-2">
            <select
              id="category"
              name="category"
              value={order}
              onChange={handleCategoryChange}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="">Select a category</option>
              {orders.map((cat) => (
                <option key={cat._id} value={cat.name}>
                  {cat.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div>
          <button
            onClick={handleSubmit}
            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-200"
          >
            Update Order
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditOrder;
