import React, { useState, useEffect } from "react";
import TopBar from "./topbar";
import { useLocation, useNavigate } from "react-router-dom";
import { FaRupeeSign } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";

const CheckoutForm = () => {
  const [products, setProducts] = useState([]);
  const location = useLocation();
  const value = location.state?.totalDiscountPrice;
  const values2 = location.state?.totalPrice;
  const [user, setUser] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  useEffect(() => {
    // Fetch user data on component mount
    const getOne = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`https://byportecommerce-ufza.onrender.com/api/v1/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data.user);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    getOne();
  }, []);

  useEffect(() => {
    const cartItems = JSON.parse(localStorage.getItem("cart")) || [];
    setProducts(cartItems);
  }, []);

  const countries = [
    {
      name: "Afghanistan",
    },
    {
      name: "Albania",
    },
    {
      name: "Algeria",
    },
    {
      name: "Andorra",
    },
    {
      name: "Angola",
    },
    {
      name: "Antigua and Barbuda",
    },
    {
      name: "Argentina",
    },
    {
      name: "Armenia",
    },
    {
      name: "Australia",
    },
    {
      name: "Austria",
    },
    {
      name: "Azerbaijan",
    },
    {
      name: "Bahamas",
    },
    {
      name: "Bahrain",
    },
    {
      name: "Bangladesh",
    },
    {
      name: "Barbados",
    },
    {
      name: "Belarus",
    },
    {
      name: "Belgium",
    },
    {
      name: "Belize",
    },
    {
      name: "Benin",
    },
    {
      name: "Bhutan",
    },
    {
      name: "Bolivia",
    },
    {
      name: "Bosnia and Herzegovina",
    },
    {
      name: "Botswana",
    },
    {
      name: "Brazil",
    },
    {
      name: "Brunei",
    },
    {
      name: "Bulgaria",
    },
    {
      name: "Burkina Faso",
    },
    {
      name: "Burundi",
    },
    {
      name: "Cabo Verde",
    },
    {
      name: "Cambodia",
    },
    {
      name: "Cameroon",
    },
    {
      name: "Canada",
    },
    {
      name: "Central African Republic",
    },
    {
      name: "Chad",
    },
    {
      name: "Chile",
    },
    {
      name: "China",
    },
    {
      name: "Colombia",
    },
    {
      name: "Comoros",
    },
    {
      name: "Congo",
    },
    {
      name: "Costa Rica",
    },
    {
      name: "Cote d'Ivoire",
    },
    {
      name: "Croatia",
    },
    {
      name: "Cuba",
    },
    {
      name: "Cyprus",
    },
    {
      name: "Czech Republic",
    },
    {
      name: "Denmark",
    },
    {
      name: "Djibouti",
    },
    {
      name: "Dominica",
    },
    {
      name: "Dominican Republic",
    },
    {
      name: "Ecuador",
    },
    {
      name: "Egypt",
    },
    {
      name: "El Salvador",
    },
    {
      name: "Equatorial Guinea",
    },
    {
      name: "Eritrea",
    },
    {
      name: "Estonia",
    },
    {
      name: "Eswatini",
    },
    {
      name: "Ethiopia",
    },
    {
      name: "Fiji",
    },
    {
      name: "Finland",
    },
    {
      name: "France",
    },
    {
      name: "Gabon",
    },
    {
      name: "Gambia",
    },
    {
      name: "Georgia",
    },
    {
      name: "Germany",
    },
    {
      name: "Ghana",
    },
    {
      name: "Greece",
    },
    {
      name: "Grenada",
    },
    {
      name: "Guatemala",
    },
    {
      name: "Guinea",
    },
    {
      name: "Guinea-Bissau",
    },
    {
      name: "Guyana",
    },
    {
      name: "Haiti",
    },
    {
      name: "Honduras",
    },
    {
      name: "Hungary",
    },
    {
      name: "Iceland",
    },
    {
      name: "India",
    },
    {
      name: "Indonesia",
    },
    {
      name: "Iran",
    },
    {
      name: "Iraq",
    },
    {
      name: "Ireland",
    },
    {
      name: "Israel",
    },
    {
      name: "Italy",
    },
    {
      name: "Jamaica",
    },
    {
      name: "Japan",
    },
    {
      name: "Jordan",
    },
    {
      name: "Kazakhstan",
    },
    {
      name: "Kenya",
    },
    {
      name: "Kiribati",
    },
    {
      name: "Korea, North",
    },
    {
      name: "Korea, South",
    },
    {
      name: "Kosovo",
    },
    {
      name: "Kuwait",
    },
    {
      name: "Kyrgyzstan",
    },
    {
      name: "Laos",
    },
    {
      name: "Latvia",
    },
    {
      name: "Lebanon",
    },
    {
      name: "Lesotho",
    },
    {
      name: "Liberia",
    },
    {
      name: "Libya",
    },
    {
      name: "Liechtenstein",
    },
    {
      name: "Lithuania",
    },
    {
      name: "Luxembourg",
    },
    {
      name: "Madagascar",
    },
    {
      name: "Malawi",
    },
    {
      name: "Malaysia",
    },
    {
      name: "Maldives",
    },
    {
      name: "Mali",
    },
    {
      name: "Malta",
    },
    {
      name: "Marshall Islands",
    },
    {
      name: "Mauritania",
    },
    {
      name: "Mauritius",
    },
    {
      name: "Mexico",
    },
    {
      name: "Micronesia",
    },
    {
      name: "Moldova",
    },
    {
      name: "Monaco",
    },
    {
      name: "Mongolia",
    },
    {
      name: "Montenegro",
    },
    {
      name: "Morocco",
    },
    {
      name: "Mozambique",
    },
    {
      name: "Myanmar",
    },
    {
      name: "Namibia",
    },
    {
      name: "Nauru",
    },
    {
      name: "Nepal",
    },
    {
      name: "Netherlands",
    },
    {
      name: "New Zealand",
    },
    {
      name: "Nicaragua",
    },
    {
      name: "Niger",
    },
    {
      name: "Nigeria",
    },
    {
      name: "North Macedonia",
    },
    {
      name: "Norway",
    },
    {
      name: "Oman",
    },
    {
      name: "Pakistan",
    },
    {
      name: "Palau",
    },
    {
      name: "Panama",
    },
    {
      name: "Papua New Guinea",
    },
    {
      name: "Paraguay",
    },
    {
      name: "Peru",
    },
    {
      name: "Philippines",
    },
    {
      name: "Poland",
    },
    {
      name: "Portugal",
    },
    {
      name: "Qatar",
    },
    {
      name: "Romania",
    },
    {
      name: "Russia",
    },
    {
      name: "Rwanda",
    },
    {
      name: "Saint Kitts and Nevis",
    },
    {
      name: "Saint Lucia",
    },
    {
      name: "Saint Vincent and the Grenadines",
    },
    {
      name: "Samoa",
    },
    {
      name: "San Marino",
    },
    {
      name: "Sao Tome and Principe",
    },
    {
      name: "Saudi Arabia",
    },
    {
      name: "Senegal",
    },
    {
      name: "Serbia",
    },
    {
      name: "Seychelles",
    },
    {
      name: "Sierra Leone",
    },
    {
      name: "Singapore",
    },
    {
      name: "Slovakia",
    },
    {
      name: "Slovenia",
    },
    {
      name: "Solomon Islands",
    },
    {
      name: "Somalia",
    },
    {
      name: "South Africa",
    },
    {
      name: "South Sudan",
    },
    {
      name: "Spain",
    },
    {
      name: "Sri Lanka",
    },
    {
      name: "Sudan",
    },
    {
      name: "Suriname",
    },
    {
      name: "Sweden",
    },
    {
      name: "Switzerland",
    },
    {
      name: "Syria",
    },
    {
      name: "Taiwan",
    },
    {
      name: "Tajikistan",
    },
    {
      name: "Tanzania",
    },
    {
      name: "Thailand",
    },
    {
      name: "Timor-Leste",
    },
    {
      name: "Togo",
    },
    {
      name: "Tonga",
    },
    {
      name: "Trinidad and Tobago",
    },
    {
      name: "Tunisia",
    },
    {
      name: "Turkey",
    },
    {
      name: "Turkmenistan",
    },
    {
      name: "Tuvalu",
    },
    {
      name: "Uganda",
    },
    {
      name: "Ukraine",
    },
    {
      name: "United Arab Emirates",
    },
    {
      name: "United Kingdom",
    },
    {
      name: "United States",
    },
    {
      name: "Uruguay",
    },
    {
      name: "Uzbekistan",
    },
    {
      name: "Vanuatu",
    },
    {
      name: "Vatican City",
    },
    {
      name: "Venezuela",
    },
    {
      name: "Vietnam",
    },
    {
      name: "Yemen",
    },
    {
      name: "Zambia",
    },
    {
      name: "Zimbabwe",
    },
  ];
  const handlerPayment = async () => {
    try {
      const totalPriceArray = products.map((item) => ({
        totalPrice: item.discount ? value : values2,
      }));

      if (totalPriceArray.length === 0) {
        console.error("No products found in cart.");
        return;
      }

      const payload = {
        amount: totalPriceArray[0].totalPrice * 100, // Razorpay expects amount in paisa
        currency: "INR",
      };

      const response = await axios.post(
        `https://byportecommerce-ufza.onrender.com/api/v1/admin/payment`,
        payload
      );
      const orderId = response.data.order.id;

      const options = {
        key: "rzp_test_dNVcBx6Oer34aC",
        amount: payload.amount,
        currency: payload.currency,
        name: "byport",
        description: "byport",
        image: "https://example.com/your_logo",
        order_id: orderId,
        handler: async function (response) {
          const body = {
            ...response,
          };

          try {
            const validateResponse = await axios.post(
              `https://byportecommerce-ufza.onrender.com/api/v1/admin/validate/payment`,
              body
            );
            console.log(validateResponse);

            toast.success("Payment is successful");
            codPayment();
          } catch (err) {
            console.log(err);
          }
        },
        recipient: {
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
        },
        prefill: {
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          // contact: user.phoneNumber,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp1 = new window.Razorpay(options);

      rzp1.on("payment.failed", function (response) {
        alert(response.error.code);
        alert(response.error.description);
        alert(response.error.source);
        alert(response.error.step);
        alert(response.error.reason);
        alert(response.error.metadata.order_id);
        alert(response.error.metadata.payment_id);
      });

      rzp1.open();
    } catch (err) {
      console.error(err);
    }
  };
  const navigate = useNavigate();
  const codPayment = async () => {
    const currentDate = new Date();
    const deliveryDate = new Date(currentDate);
    deliveryDate.setDate(currentDate.getDate() + 7); // Adding 7 days to the current date

    const orderItems = products.map((item) => ({
      name: item.name,
      price: item.discount ? value : values2,
      quantity: item.quantity,
      image: item.images[0].url,
      product: item._id,
      size: item.size,
    }));

    const totalPriceArray = products.map((item) => ({
      totalPrice: item.discount ? value : values2,
    }));

    const payload = {
      shippingInfo: {
        firstName,
        lastName,
        email,
        address,
        city,
        state,
        country,
        pincode,
        phoneNumber,
      },
      user: user._id,
      orderItems,
      paymentInfo: {
        id: "paymentId",
        status: "success",
      },
      paidAt: currentDate,
      totalPrice: totalPriceArray[0].totalPrice,
      orderStatus: "Processing",
      deliveredAt: deliveryDate,
      createdAt: currentDate,
    };

    try {
      const response = await axios.post(
        `https://byportecommerce-ufza.onrender.com/api/v1/order/new`,
        payload
      );
      console.log(response.data);
      navigate("/");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <TopBar />
      <div className="bg-white shadow-lg">
        <div className="flex flex-col md:flex-row justify-center my-6">
          <div className="w-full md:w-1/2 px-4 mb-4 md:mb-0">
            {/* Left side - Personal Information */}
            <h2 className="text-xl font-semibold mb-4">Personal Information</h2>
            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8">
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="fullName"
                >
                  Full Name
                </label>
                <div className="flex">
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="firstName"
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ml-2"
                    id="lastName"
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="city"
                >
                  email
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="city"
                  type="text"
                  placeholder="City"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="address"
                >
                  Address
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="address"
                  type="text"
                  placeholder="Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="city"
                >
                  City
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="city"
                  type="text"
                  placeholder="City"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4 flex flex-wrap">
                <div className="w-full md:w-1/2 md:pr-2 mb-2 md:mb-0">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="state"
                  >
                    State
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="state"
                    type="text"
                    placeholder="State"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    required
                  />
                </div>
                <div className="w-full md:w-1/2 md:pl-2 mb-2 md:mb-0">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="country"
                  >
                    Country
                  </label>
                  <select
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="country"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    required
                  >
                    <option value="">Select your country</option>
                    {countries.map((country, index) => (
                      <option key={index} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="postcode"
                >
                  Postcode
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="postcode"
                  type="text"
                  placeholder="Postcode"
                  value={pincode}
                  onChange={(e) => setPincode(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="phone"
                >
                  Phone Number
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="phone"
                  type="tel"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              </div>
              <button
                onClick={handlerPayment}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Confirm Order
              </button>
            </div>
          </div>

          <div className="w-full md:w-1/2 px-4">
            {/* Right side - Product List */}
            <h2 className="text-xl font-semibold mb-4">Product List</h2>
            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8">
              {products.map((product) => (
                <div key={product.id} className="flex mb-4">
                  <div className="w-1/4" style={{ border: "1px solid indigo" }}>
                    <img
                      src={product.images[0].url}
                      alt={product.imageAlt}
                      className="w-full h-auto"
                    />
                  </div>
                  <div className="w-3/4 ml-4">
                    <p className="text-gray-700 font-semibold">
                      {product.name}
                    </p>
                    <div className="flex items-center">
                      <FaRupeeSign size={20} />
                      <p className="text-gray-500">
                        {product.discount ? value : values2}
                      </p>
                    </div>
                    <p className="text-gray-500">
                      Quantity: {product.quantity}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutForm;
