import React, { useEffect, useRef, useState } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import { RxHamburgerMenu } from "react-icons/rx";
import { FaRegUserCircle, FaCartPlus, FaShoppingCart } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const navigation = [
  {
    name: "Groceries",
    href: "/groceries",
  },
  {
    name: "Dry Fruits",
    href: "/dry-fruits",
  },
  {
    name: "Dry Fish",
    href: "/dry-fish",
  },
  {
    name: "Masala",
    href: "/masala",
  },
  {
    name: "Sweet",
    href: "/sweet",
  },
];

const TopBar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [cartItemsCount, setCartItemsCount] = useState(0); // State to hold cart items count
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch user data on component mount
    const getOne = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `https://byportecommerce-ufza.onrender.com/api/v1/me`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUser(response.data.user);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    getOne();
  }, []);

  useEffect(() => {
    // Close dropdown menu when clicking outside of it
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Fetch cart items from local storage and calculate total items count
    const cartItems = JSON.parse(localStorage.getItem("cart")) || [];
    const totalCount = cartItems.reduce((acc, item) => acc + item.quantity, 0);
    setCartItemsCount(totalCount);
  }, []);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleLogout = () => {
    // Implement your logout functionality here
    axios
      .get("https://byportecommerce-ufza.onrender.com/api/v1/logout")
      .then((res) => {
        console.log("Logging out...");
        // Example: Clear localStorage
        localStorage.removeItem("token");
        // Close the dropdown after logout
        setShowDropdown(false);
        // Navigate to the home page or login page after logout
        navigate("/");
      });
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  console.log(cartItemsCount);
  return (
    <header className="bg-white shadow-lg relative">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <a href="#" className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <img
              className="h-8 w-auto"
              src="https://res.cloudinary.com/dq9pir6ve/image/upload/v1720260165/images/wqga0vcdsrmaeoehhkev.png"
              alt=""
            />
          </a>
        </div>
        {/* <div className="flex lg:hidden items-center relative">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 ml-auto"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <RxHamburgerMenu className="h-6 w-6" aria-hidden="true" />
          </button>
        </div> */}

        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              className="flex items-center gap-x-1 text-lg font-semibold leading-6 text-gray-900"
            >
              {item.name}
            </Link>
          ))}
        </div>
        {user ? (
          <div className="hidden lg:flex lg:flex-1 lg:justify-end items-center relative">
            <div
              className="flex items-center relative"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/cart")}
            >
              <FaShoppingCart size={20} style={{ marginRight: "8px" }} />
              <div className="relative">
                {cartItemsCount > 0 && (
                  <div className="absolute -top-1 -right-1 h-5 w-5 bg-red-500 text-white flex items-center justify-center rounded-full ">
                    {cartItemsCount}
                  </div>
                )}
              </div>
            </div>
            <div
              className="relative group"
              onClick={toggleDropdown}
              ref={dropdownRef}
            >
              <img
                src={user.avatar.url}
                alt="User Avatar"
                style={{
                  border: "1px solid grey",
                  borderRadius: "50px",
                }}
                className="w-10 h-10 rounded-full cursor-pointer hover:opacity-80 transition-opacity duration-300"
              />
              {showDropdown && (
                <div className="absolute right-0 z-10 mt-2 w-48 origin-top-right bg-white shadow-lg rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {user.roles === "admin" && (
                    <div style={{ padding: "10px", cursor: "pointer" }}>
                      <span onClick={() => navigate("/dashboard")}>
                        Dashboard
                      </span>
                    </div>
                  )}

                  <div style={{ padding: "10px", cursor: "pointer" }}>
                    <span onClick={() => navigate("/my-profile")}>Profile</span>
                  </div>
                  <div style={{ padding: "10px", cursor: "pointer" }}>
                    <span onClick={handleLogout}>Log Out</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <Link
              to="/login"
              className="text-lg font-semibold leading-6 text-gray-900"
            >
              Log in <span aria-hidden="true">&rarr;</span>
            </Link>
          </div>
        )}
      </nav>
      <Dialog
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
      >
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">By Port</span>
              <img
                className="h-8 w-auto"
                src="https://res.cloudinary.com/dq9pir6ve/image/upload/v1720260165/images/wqga0vcdsrmaeoehhkev.png"
                alt=""
              />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <FaRegUserCircle className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>

              <div className="py-6">
                <Link
                  to="/login"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Log in
                </Link>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
      {/* Display cart item count on top of the shopping cart icon */}
    </header>
  );
};

export default TopBar;
