import { useEffect, useState } from "react";
import TopBar from "./topbar";
import { Link, useNavigate } from "react-router-dom";
import { FaRupeeSign } from "react-icons/fa";

const Cart = () => {
  const [open, setOpen] = useState(true);
  const [cartItems, setCartItems] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const cartItems = JSON.parse(localStorage.getItem("cart")) || [];
    setCartItems(cartItems);
  }, []);

  const handleIncrement = (id) => {
    const updatedCartItems = cartItems.map((item) =>
      item.id === id ? { ...item, quantity: item.quantity + 1 } : item
    );
    setCartItems(updatedCartItems);
    localStorage.setItem("cart", JSON.stringify(updatedCartItems));
  };

  const handleDecrement = (id) => {
    const updatedCartItems = cartItems.map((item) =>
      item.id === id && item.quantity > 1
        ? { ...item, quantity: item.quantity - 1 }
        : item
    );
    setCartItems(updatedCartItems);
    localStorage.setItem("cart", JSON.stringify(updatedCartItems));
  };

  const handleRemove = (id) => {
    const updatedCartItems = cartItems.filter((item) => item.id !== id);
    setCartItems(updatedCartItems);
    localStorage.setItem("cart", JSON.stringify(updatedCartItems));
  };

  const calculateSubtotal = () => {
    return cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
  };

  const calculateDiscountedTotal = () => {
    // Calculate discounted subtotal
    const discountedSubtotal = cartItems.reduce((acc, item) => {
      // Check if item has a discount percentage specified
      if (item.discount) {
        const discountedPrice = item.price * (1 - item.discount / 100);
        return acc + discountedPrice * item.quantity;
      } else {
        return acc + item.price * item.quantity;
      }
    }, 0);

    return discountedSubtotal.toFixed(2);
  };
  const navigateToCheckout = () => {
    // Check if token exists in localStorage
    const token = localStorage.getItem("token");
    if (token) {
      const state = {
        a: cartItems,
        totalDiscountPrice: calculateDiscountedTotal(),
        totalPrice: calculateSubtotal(),
      };
      navigate("/checkout", { state });
    } else {
      // Redirect to login screen if token does not exist
      navigate("/login");
    }
  };
  return (
    <>
      <TopBar />
      <div
        className="flex h-full flex-col bg-white shadow-xl"
        style={{ marginLeft: "20px", marginRight: "20px", marginTop: "20px" }}
      >
        <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
          <div className="flex items-start justify-between">
            <h1 className="text-lg font-medium text-gray-900">Shopping cart</h1>
            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className="relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                onClick={() => setOpen(false)}
              >
                <span className="absolute -inset-0.5" />
                <span className="sr-only">Close panel</span>
              </button>
            </div>
          </div>

          <div className="mt-8">
            <div className="flow-root">
              <ul role="list" className="-my-6 divide-y divide-gray-200">
                {cartItems.map((product) => (
                  <li key={product.id} className="flex py-6">
                    <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                      <img
                        src={product.images[0].url}
                        alt={product.imageAlt}
                        className="h-full w-full object-cover object-center"
                      />
                    </div>

                    <div className="ml-4 flex flex-1 flex-col">
                      <div>
                        <div className="flex justify-between text-base font-medium text-gray-900">
                          <h3>{product.name}</h3>
                          <div className="flex items-center">
                            <FaRupeeSign size={20} />
                            <p className="ml-2">{product.price.toFixed(2)}</p>
                          </div>
                        </div>
                        {/* Uncomment if you want to display product color */}
                        {/* <p className="mt-1 text-sm text-gray-500">{product.color}</p> */}
                        {product.discount && (
                          <p className="mt-1 text-sm text-gray-500">
                            {product.discount} % off
                          </p>
                        )}
                      </div>
                      <div className="flex flex-1 items-end justify-between text-sm">
                        <p className="text-gray-500">Qty {product.quantity}</p>
                        <div className="flex justify-center w-1/5">
                          <button
                            onClick={() => handleDecrement(product.id)}
                            className="px-2 py-1 bg-gray-200 rounded-md hover:bg-gray-300"
                          >
                            -
                          </button>
                          <input
                            className="mx-2 border text-center w-8"
                            type="text"
                            value={product.quantity}
                            readOnly
                          />
                          <button
                            onClick={() => handleIncrement(product.id)}
                            className="px-2 py-1 bg-gray-200 rounded-md hover:bg-gray-300"
                          >
                            +
                          </button>
                        </div>
                        <div className="flex">
                          <button
                            type="button"
                            className="font-medium text-indigo-600 hover:text-indigo-500"
                            onClick={() => handleRemove(product.id)}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
          <div className="flex justify-between text-base font-medium text-gray-900">
            <p>Subtotal</p>
            <div className="flex items-center">
              <FaRupeeSign size={20} />
              <p>{calculateSubtotal().toFixed(2)}</p>
            </div>
          </div>

          <div className="flex justify-between text-base font-medium text-gray-900">
            <p>Discounted Total</p>
            <div className="flex items-center">
              <FaRupeeSign size={20} />
              <p>{calculateDiscountedTotal()}</p>
            </div>
          </div>
          <p className="mt-0.5 text-sm text-gray-500">
            Shipping and taxes calculated at checkout.
          </p>
          <div
            className="mt-6"
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <button
              className="flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
              style={{ width: "25%" }}
              onClick={navigateToCheckout}
            >
              Checkout
            </button>
          </div>
          <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
            <p>
              or{" "}
              <button
                type="button"
                className="font-medium text-indigo-600 hover:text-indigo-500"
                onClick={() => setOpen(false)}
              >
                Continue Shopping
                <span aria-hidden="true"> &rarr;</span>
              </button>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cart;
