import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { FaRupeeSign } from "react-icons/fa";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import RatingStars from "react-rating-stars-component";
import ReviewCard from "./reviewCard";
const ProductDetail = () => {
  const [product, setProduct] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [stock, setStock] = useState(1); // State to track product stock
  const { id } = useParams();
  const [openReview, setOpenReview] = useState();
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [user, setUser] = useState(null);

  const getOneProduct = () => {
    axios
      .get(`https://byportecommerce-ufza.onrender.com/api/v1/product/${id}`)
      .then((res) => {
        const fetchedProduct = res.data.product;
        setProduct(fetchedProduct);
        setStock(1); // Set initial stock value
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getOneProduct();
  }, []);
  const [users, setUsers] = useState(null);

  useEffect(() => {
    // Fetch user data if token exists
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const response = await axios.get(
            `https://byportecommerce-ufza.onrender.com/api/v1/me`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setUsers(response.data.user);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserData();
  }, []);
  useEffect(() => {
    // Fetch user data on component mount
    const getOne = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `https://byportecommerce-ufza.onrender.com/api/v1/me`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUser(response.data.user);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    getOne();
  }, []);
  console.log(user);
  const discountedPrice = product?.discount
    ? product?.price - product?.price * (product?.discount / 100)
    : product?.price;
  const selectedsPrice = selectedPrice
    ? selectedPrice - selectedPrice * (product?.discount / 100)
    : "";
  const handleSizeSelect = (sizevalue, price) => {
    setSelectedSize(sizevalue);
    setSelectedPrice(price);
  };

  const handleIncrement = () => {
    if (stock < product.stock) {
      setStock((prevStock) => prevStock + 1);
    }
  };

  const handleDecrement = () => {
    if (stock > 0) {
      setStock((prevStock) => prevStock - 1);
    }
  };
  const navigate = useNavigate();
  const addToCart = () => {
    // Constructing cart item with entire product and selected size/price
    const cartItem = {
      ...product,

      size: selectedSize,
      selectedPrice: selectedPrice,
      quantity: stock,
    };

    // Retrieve existing cart items from local storage
    const existingCartItems = JSON.parse(localStorage.getItem("cart")) || [];

    // Add new item to cart
    existingCartItems.push(cartItem);

    // Store updated cart items back in local storage
    localStorage.setItem("cart", JSON.stringify(existingCartItems));
    navigate("/cart");
    console.log("Added to cart:", cartItem);
    alert("Item added to cart!");
  };

  if (!product) {
    return <div>Loading...</div>; // Loading state until product data is fetched
  }
  const handleReview = () => {
    setOpenReview(true);
  };
  const handleClose = () => {
    setOpenReview(false);
  };

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };
  const handleSubmit = async () => {
    const formData = {
      rating: rating,
      comment: comment,
      productId: product._id, // Replace with actual product ID
      user: user,
    };

    try {
      // Make API call to submit formData
      const response = await axios.put(
        "https://byportecommerce-ufza.onrender.com/api/v1/review",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      handleClose();
      // Handle success scenario (e.g., show success message)
    } catch (error) {
      // Handle error scenario (e.g., show error message)
    }
  };

  return (
    <div className="bg-white py-6 sm:py-8 lg:py-12">
      <div className="mx-auto max-w-screen-xl px-4 md:px-8">
        <div className="grid gap-8 md:grid-cols-2">
          <div className="grid gap-4 lg:grid-cols-5">
            <div className="order-last flex gap-4 lg:order-none lg:flex-col">
              {product.images.slice(0, 3).map((image, index) => (
                <div
                  key={index}
                  className="overflow-hidden rounded-lg bg-gray-100"
                >
                  <img
                    src={image.url}
                    loading="lazy"
                    alt={`Product ${index + 1}`}
                    className="h-full w-full object-cover object-center"
                  />
                </div>
              ))}
            </div>

            <div className="relative overflow-hidden rounded-lg bg-gray-100 lg:col-span-4">
              <img
                src={product.images[0].url}
                loading="lazy"
                alt="Product Main"
                className="h-full w-full object-cover object-center"
              />
              {product.discount && (
                <span className="absolute left-0 top-0 rounded-br-lg bg-red-500 px-3 py-1.5 text-sm uppercase tracking-wider text-white">
                  sale
                </span>
              )}
            </div>
          </div>

          <div className="md:py-8">
            <div className="mb-2 md:mb-3">
              <span className="mb-0.5 inline-block text-gray-500">
                {product.category}
              </span>
              <h2 className="text-2xl font-bold text-gray-800 lg:text-3xl">
                {product.name}
              </h2>
            </div>

            <div className="mb-6 flex items-center gap-3 md:mb-10">
              <div className="flex h-7 items-center gap-1 rounded-full bg-indigo-500 px-2 text-white">
                <span className="text-sm">{product.ratings.toFixed(2)}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
              </div>
              <span className="text-sm text-gray-500 transition duration-100">
                {product.reviews.length} ratings
              </span>
            </div>

            <div className="flex items-end gap-2 mb-6">
              <FaRupeeSign size={20} />
              <span className="text-xl font-bold text-gray-800 md:text-2xl">
                {selectedsPrice ? selectedsPrice : discountedPrice}
              </span>
              {product.discount && (
                <span className="mb-0.5 text-red-500 line-through">
                  {product.price} {product.discount}% off
                </span>
              )}
            </div>

            <div className="mb-6 flex items-center gap-3">
              <div className="flex justify-center w-1/5">
                <button
                  onClick={handleDecrement}
                  className="px-2 py-1 bg-gray-200 rounded-md hover:bg-gray-300"
                >
                  -
                </button>
                <input
                  className="mx-2 border text-center w-8"
                  type="text"
                  value={stock}
                  readOnly
                />
                <button
                  onClick={handleIncrement}
                  className="px-2 py-1 bg-gray-200 rounded-md hover:bg-gray-300"
                >
                  +
                </button>
              </div>
            </div>
            <span className="mb-3 inline-block text-sm font-semibold text-gray-500 md:text-base">
              Size
            </span>
            <div
              className="mb-8 md:mb-10"
              style={{ display: "flex", gap: "20px", flexWrap: "nowrap" }}
            >
              {product.size.map((size, index) => (
                <button
                  key={index}
                  type="button"
                  onClick={() => handleSizeSelect(size.sizevalue, size.price)}
                  className={`flex h-8 w-13 items-center justify-center rounded-md border ${
                    selectedSize === size.sizevalue
                      ? "bg-indigo-500 text-white"
                      : "bg-white text-gray-800"
                  } text-center text-sm font-semibold transition duration-100 hover:bg-gray-100 active:bg-gray-200`}
                >
                  {size.sizevalue}
                </button>
              ))}
            </div>

            <div className="flex gap-2.5 mb-6">
              <button
                onClick={addToCart}
                className="inline-block flex-1 rounded-lg bg-indigo-500 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-indigo-300 transition duration-100 hover:bg-indigo-600 focus-visible:ring active:bg-indigo-700 sm:flex-none md:text-base"
              >
                Add to cart
              </button>
              {users && localStorage.getItem("token") && (
                <button
                  onClick={handleReview}
                  className="inline-block rounded-lg bg-gray-200 px-8 py-3 text-center text-sm font-semibold text-gray-500 outline-none ring-indigo-300 transition duration-100 hover:bg-gray-300 focus-visible:ring active:text-gray-700 md:text-base"
                >
                  Review
                </button>
              )}
            </div>

            <div className="mt-10 md:mt-16 lg:mt-20">
              <div className="mb-3 text-lg font-semibold text-gray-800">
                Description
              </div>
              <p className="text-gray-500">{product.description}</p>
            </div>
          </div>
        </div>
      </div>
     
      <ReviewCard prodId={id} />
      <Dialog open={openReview} onClose={handleClose}>
        <DialogContent>
          <RatingStars
            count={5}
            size={40}
            value={rating}
            onChange={handleRatingChange}
            half={false}
            edit={true}
            isHalf={false}
          />
          <TextField
            multiline
            rows={4}
            variant="outlined"
            margin="normal"
            fullWidth
            label="Your Review"
            value={comment}
            onChange={handleCommentChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ProductDetail;
