import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { IoIosCloseCircleOutline } from "react-icons/io";

const AddBanner = ({ onClose }) => {
  const [title, setTitle] = useState("");
  const [discount, setDiscount] = useState("");
  const [avatar, setAvatar] = useState(null); // For image file
  const [avatarPreview, setAvatarPreview] = useState(null); // For image preview

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAvatar(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatarPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      // Optionally handle case where no file is selected
      console.log('No file selected');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    // Append data to FormData
    formData.append("title", title);
    formData.append("discount", discount);
    formData.append("avatar", avatarPreview);

    try {
      const response = await axios.post(
        "https://byportecommerce-ufza.onrender.com/api/v1/admin/get-all-create",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Image created:", response.data);
      onClose();
      toast.success("Image created successfully");
    } catch (error) {
      console.error("Error creating image:", error);
      toast.error("Error creating image");
    }
  };

  return (
    <div
      className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm"
      style={{ margin: "1rem" }}
    >
      <div
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "end",
          cursor: "pointer",
        }}
      >
        <IoIosCloseCircleOutline size={20} onClick={onClose} />
      </div>
      <div>
        <div className="space-y-6">
          <div>
            <label
              htmlFor="title"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Title
            </label>
            <div className="mt-2">
              <input
                id="title"
                name="title"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="discount"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Discount
            </label>
            <div className="mt-2">
              <input
                id="discount"
                name="discount"
                type="text"
                value={discount}
                onChange={(e) => setDiscount(e.target.value)}
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="avatar"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Upload Image
            </label>
            <div className="mt-2">
              <input
                id="avatar"
                name="avatars"
                type="file"
                accept=".jpg, .jpeg, .png"
                onChange={handleAvatarChange}
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          {avatarPreview && (
            <div className="mt-4">
              <img
                src={avatarPreview}
                alt="Preview"
                className="w-full h-auto rounded-md border border-gray-300"
              />
            </div>
          )}

          <div>
            <button
              onClick={handleSubmit}
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBanner;
