import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { IoIosCloseCircleOutline } from "react-icons/io";
const EditAdmin = ({ id, onClose }) => {
  const [roles, setRoles] = useState();
  const [user, setUserId] = useState();
  const type = [
    {
      name: "admin",
    },
    {
      name: "vendor",
    },
    {
      name: "user",
    },
  ];
  const getOneUser = () => {
    axios
      .get(`https://byportecommerce-ufza.onrender.com/api/v1/admin/user/${id}`)
      .then((res) => {
        console.log(res);
        setRoles(res.data.user.roles);
        setUserId(res.data.user._id);
      });
  };
  useEffect(() => {
    getOneUser(id);
  }, []);
  const handleSubmit = () => {
    const payload = {
      roles: roles,
    };
    axios
      .put(
        `https://byportecommerce-ufza.onrender.com/api/v1/admin/user/${user}`,
        payload
      )
      .then((res) => {
        toast.success("User Updated successfully");
        onClose()
      });
  };
  return (
    <div
      className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm"
      style={{ margin: "1rem" }}
    >
      <div
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "end",
          cursor: "pointer",
        }}
      >
        <IoIosCloseCircleOutline size={20} onClick={onClose} />
      </div>
      <div>
        <div className="space-y-6">
          <div>
            <label
              htmlFor="categoryName"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Roles
            </label>
            <div className="mt-2">
              <select
                id="category"
                name="category"
                value={roles}
                onChange={(e) => setRoles(e.target.value)}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="">Select a category</option>
                {type.map((cat) => (
                  <option key={cat._id} value={cat.name}>
                    {cat.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Subcategories */}

          <div>
            <button
              onClick={handleSubmit}
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Edit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAdmin;
