import React, { useEffect, useState } from "react";
import AdminHeader from "./adminHeader";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import axios from "axios";
import EditAdmin from "./editAdmin";
import { DataGrid } from "@mui/x-data-grid";

const UserList = () => {
  const [openEdit, setOpenEdit] = useState(false);
  const [product, setProduct] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 10; // Number of products per page
  const [totalProducts, setTotalProducts] = useState(0);
  const [categoryId, setCategoryId] = useState();

  useEffect(() => {
    getAllUsers();
  }, [currentPage]);

  const getAllUsers = () => {
    axios
      .get("https://byportecommerce-ufza.onrender.com/api/v1/admin/users")
      .then((res) => {
        setProduct(res.data.users);
        setTotalProducts(res.data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEditClick = (id) => {
    setCategoryId(id);
    setOpenEdit(true);
  };
const fetsClose = ()=>{
  getAllUsers()
  setOpenEdit(false)
}
  const columns = [
    { field: "avatar", headerName: "Image", flex: 1, renderCell: (params) => (
      <img src={params.value?.url} alt="avatar" style={{ width: "50px", height: "50px", borderRadius: "50%" }} />
    )},
    { field: "name", headerName: "Name", flex: 1, renderCell: (params) => (
      `${params.row.firstName} ${params.row.lastName}`
    )},
    { field: "email", headerName: "Email", flex: 1 },
    { field: "roles", headerName: "Roles", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <Link
          className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
          onClick={() => handleEditClick(params.row._id)}
        >
          Edit
        </Link>
      ),
    },
  ];

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(totalProducts / productsPerPage);

  return (
    <div>
      <AdminHeader />
      <ToastContainer />
      <div style={{ height: 400, width: "75%", margin: "2rem" }}>
        <DataGrid
          rows={product}
          columns={columns}
          pageSize={productsPerPage}
          rowCount={totalProducts}
          pagination
          onPageChange={(newPage) => paginate(newPage.page)}
          getRowId={(row) => row._id}

        />
      </div>
    
      <Dialog open={openEdit} onClose={() => setOpenEdit(false)}>
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
        <div className="fixed inset-0 z-10 flex items-center justify-center p-4">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl sm:w-full sm:max-w-lg"
          >
            <EditAdmin onClose={fetsClose} id={categoryId} />
          </DialogPanel>
        </div>
      </Dialog>
    </div>
  );
};

export default UserList;
