import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { IoIosCloseCircleOutline } from "react-icons/io";
const AddCategories = ({ onClose }) => {
  const [name, setName] = useState("");
  const [subcategories, setSubcategories] = useState([{ name: "" }]);
  const handleSubmit = async (e) => {
    const token = localStorage.getItem("token")
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://byportecommerce-ufza.onrender.com/api/v1/create-category",
        {
          name,
          subcategories,
        },
        {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
      );
      console.log("Category created:", response.data);
      onClose();
      toast.success("Categories created Successfully");
      // Handle success (e.g., show a success message)
    } catch (error) {
      console.error("Error creating category:", error);
      // Handle error (e.g., show an error message)
    }
  };
  const handleSubcategoryChange = (index, value) => {
    const updatedSubcategories = [...subcategories];
    updatedSubcategories[index] = { name: value };
    setSubcategories(updatedSubcategories);
  };

  const addSubcategory = () => {
    setSubcategories([...subcategories, { name: "" }]);
  };

  const removeSubcategory = (index) => {
    const updatedSubcategories = [...subcategories];
    updatedSubcategories.splice(index, 1);
    setSubcategories(updatedSubcategories);
  };
  return (
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm" 
      style={{ margin: "1rem" }}
      
      >
        <div style={{position: "relative",
    display: "flex",
    justifyContent: "end",
    cursor: "pointer"}}>
        <IoIosCloseCircleOutline size={20} onClick={onClose} />
      </div>
      <div>
        <div className="space-y-6">
          <div>
            <label
              htmlFor="categoryName"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Name
            </label>
            <div className="mt-2">
              <input
                id="categoryName"
                name="categoryName"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          {/* Subcategories */}
          {subcategories.map((subcategory, index) => (
            <div key={index}>
              <label
                htmlFor={`subcategory-${index}`}
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Subcategory {index + 1}
              </label>
              <div className="mt-2 flex">
                <input
                  id={`subcategory-${index}`}
                  name={`subcategory-${index}`}
                  type="text"
                  value={subcategory.name}
                  onChange={(e) =>
                    handleSubcategoryChange(index, e.target.value)
                  }
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {index === 0 && (
                  <button
                    type="button"
                    onClick={() => removeSubcategory(index)}
                    className="ml-2 px-2 py-1.5 rounded-md bg-red-600 text-white text-xs font-semibold leading-4 shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:ring-2 focus-visible:ring-red-600 focus-visible:ring-offset-2"
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          ))}

          {/* Add Subcategory Button */}
          <div className="mt-2">
            <button
              type="button"
              onClick={addSubcategory}
              className="px-3 py-1.5 rounded-md bg-green-600 text-white text-sm font-semibold leading-6 shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:ring-2 focus-visible:ring-green-600 focus-visible:ring-offset-2"
            >
              Add Subcategory
            </button>
          </div>

          {/* Submit Button */}
          <div>
            <button
              onClick={handleSubmit}
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCategories;
