import React, { useEffect, useState } from "react";
import AdminHeader from "./adminHeader";
import axios from "axios";
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, ArcElement);

const Dashboard = () => {
  const [totalProducts, setTotalProducts] = useState(0);
  const [product, setProduct] = useState(0);
  const [products, setProducts] = useState(0);
  const [amount, setAmount] = useState(0);

  const getAllUsers = () => {
    axios
      .get("https://byportecommerce-ufza.onrender.com/api/v1/admin/users")
      .then((res) => {
        setTotalProducts(res.data.users);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllCatgeories = () => {
    axios
      .get("https://byportecommerce-ufza.onrender.com/api/v1/admin/orders")
      .then((res) => {
        setProduct(res.data.orders);
        setAmount(res.data.totalAmount);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllCatgeories();
  }, []);

  const getAllCatgeoriess = () => {
    const token = localStorage.getItem("token");

    axios
      .get(
        "https://byportecommerce-ufza.onrender.com/api/v1/products",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setProducts(res.data.products);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllCatgeoriess();
  }, []);

  const data = {
    labels: ['Total Amount', 'Product Count', 'Order Count', 'User Count'],
    datasets: [
      {
        label: 'Dashboard Metrics',
        data: [amount, products.length, product.length, totalProducts.length],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <AdminHeader />
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-2xl font-bold mb-4">Dashboard</h1>

        {/* Summary Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {/* Total Price Card */}
          <div className="bg-white p-4 rounded-lg shadow-md">
            <h2 className="text-lg font-semibold mb-2">Total Amount</h2>
            <div className="text-3xl font-bold text-gray-800">{amount}</div>
          </div>

          {/* Product Count Card */}
          <div className="bg-white p-4 rounded-lg shadow-md">
            <h2 className="text-lg font-semibold mb-2">Product Count</h2>
            <div className="text-3xl font-bold text-gray-800">
              {products.length}
            </div>
          </div>

          {/* Order Count Card */}
          <div className="bg-white p-4 rounded-lg shadow-md">
            <h2 className="text-lg font-semibold mb-2">Order Count</h2>
            <div className="text-3xl font-bold text-gray-800">{product.length}</div>
          </div>

          {/* User Count Card */}
          <div className="bg-white p-4 rounded-lg shadow-md">
            <h2 className="text-lg font-semibold mb-2">User Count</h2>
            <div className="text-3xl font-bold text-gray-800">
              {totalProducts.length}
            </div>
          </div>
        </div>

        {/* Pie Chart Section */}
        <div className="mt-8">
          <h2 className="text-xl font-semibold mb-4">Dashboard Metrics</h2>
          <div className="bg-white p-4 rounded-lg shadow-md">
            <Pie data={data} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
