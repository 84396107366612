import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import groc from "../../src/images/grocer.jpeg";
import sweet from "../../src/images/sweet.png";

import fruit from "../../src/images/dry.jpeg";
import masala from "../../src/images/spices.jpeg";
import fish from "../../src/images/dryfish.png";
import home from "../../src/images/home.jpeg";
import setting from "../../src/images/setting.png";

const CategoriesIcon = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Fetch user data if token exists
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const response = await axios.get(
            `https://byportecommerce-ufza.onrender.com/api/v1/me`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setUser(response.data.user);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserData();
  }, []);
  return (
    <div className="flex flex-wrap justify-center sm:hidden">
      {/* Category 1 */}
      <div className="w-1/3 p-2">
        <Link to="/groceries">
          <div className="bg-white p-4 rounded-lg shadow-md text-center">
            <img
            src={groc}
              alt="Category Icon"
              className="w-16 h-16 mx-auto rounded-full object-cover mb-2"
            />
            <p className="text-xs">Groceries</p>
          </div>
        </Link>
      </div>

      {/* Category 2 */}
      <div className="w-1/3 p-2">
        <Link to="/sweet">
          <div className="bg-white p-4 rounded-lg shadow-md text-center">
            <img
            src={sweet}
              alt="Category Icon"
              className="w-16 h-16 mx-auto rounded-full object-cover mb-2"
            />
            <p className="text-xs">Sweet</p>
          </div>
        </Link>
      </div>

      {/* Category 3 */}
      <div className="w-1/3 p-2">
        <Link to="/masala">
          <div className="bg-white p-4 rounded-lg shadow-md text-center">
            <img
            src={masala}
              alt="Category Icon"
              className="w-16 h-16 mx-auto rounded-full object-cover mb-2"
            />
            <p className="text-xs">Masala</p>
          </div>
        </Link>
      </div>

      {/* Category 4 */}
      <div className="w-1/3 p-2">
        <Link to="/dry-fruits">
          <div className="bg-white p-4 rounded-lg shadow-md text-center">
            <img
            src={fruit}
              alt="Category Icon"
              className="w-16 h-16 mx-auto rounded-full object-cover mb-2"
            />
            <p className="text-xs">Dry Fruit</p>
          </div>
        </Link>
      </div>

      {/* Category 5 */}
      <div className="w-1/3 p-2">
        <Link to="/dry-fish">
          <div className="bg-white p-4 rounded-lg shadow-md text-center">
            <img
            src={fish}
              alt="Category Icon"
              className="w-16 h-16 mx-auto rounded-full object-cover mb-2"
            />
            <p className="text-xs">Dry Fish</p>
          </div>
        </Link>
      </div>
      <div className="w-1/3 p-2">
        <Link to="/">
          <div className="bg-white p-4 rounded-lg shadow-md text-center">
            <img
            src={home}
              alt="Category Icon"
              className="w-16 h-16 mx-auto rounded-full object-cover mb-2"
            />
            <p className="text-xs">Home</p>
          </div>
        </Link>
      </div>
      {user && localStorage.getItem("token") && (
        <div className="w-1/3 p-2">
          <Link to="/setting">
            <div className="bg-white p-4 rounded-lg shadow-md text-center">
              <img
              src={setting}
                alt="Category Icon"
                className="w-16 h-16 mx-auto rounded-full object-cover mb-2"
              />
              <p className="text-xs">Settings</p>
            </div>
          </Link>
        </div>
      )}
    </div>
  );
};

export default CategoriesIcon;
