import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [otpSent, setOtpSent] = useState(false);
  const [timer, setTimer] = useState(0);
  const [canResend, setCanResend] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    let interval;
    if (otpSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            clearInterval(interval);
            setCanResend(true);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } else if (timer === 0) {
      setCanResend(true);
    }

    return () => clearInterval(interval);
  }, [otpSent, timer]);

  const handleSendOtp = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await axios.post(
        `https://byportecommerce-ufza.onrender.com/api/v1/admin/sendOtp`,
        { email }
      );
      setOtpSent(true);
      setCanResend(false);
      setTimer(300); // Set timer to 5 minutes (300 seconds)
      setSuccess("OTP sent successfully!");
      console.log(response.data); // Handle the response data as needed
    } catch (err) {
      setError("Failed to send OTP. Please try again.");
      console.error(err); // Handle the error as needed
    } finally {
      setLoading(false);
    }
  };
  const handleResendSendOtp = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await axios.post(
        `https://byportecommerce-ufza.onrender.com/api/v1/admin/resend`,
        { email }
      );
      setOtpSent(true);
      setCanResend(false);
      setTimer(300); // Set timer to 5 minutes (300 seconds)
      setSuccess("OTP sent successfully!");
      console.log(response.data); // Handle the response data as needed
    } catch (err) {
      setError("Failed to send OTP. Please try again.");
      console.error(err); // Handle the error as needed
    } finally {
      setLoading(false);
    }
  };
  const handleVerifyOtp = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await axios.post(
        "https://byportecommerce-ufza.onrender.com/api/v1/admin/verify",
        { email, otp }
      );
      setSuccess("OTP verified successfully!");
      navigate("/reset-password", { state: { email } });
      console.log(response.data); // Handle the response data as needed
    } catch (err) {
      setError("Failed to verify OTP. Please try again.");
      console.error(err); // Handle the error as needed
    } finally {
      setLoading(false);
    }
  };

  const handleResendOtp = () => {
    setOtpSent(false);
    handleResendSendOtp();
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-10 w-auto"
            src="https://res.cloudinary.com/dq9pir6ve/image/upload/v1720260165/images/wqga0vcdsrmaeoehhkev.png"
            alt="Your Company"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Forgot Password
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Enter Email
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            {otpSent && (
              <div>
                <label
                  htmlFor="otp"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Enter OTP
                </label>
                <div className="mt-2">
                  <input
                    id="otp"
                    name="otp"
                    type="text"
                    value={otp}
                    onChange={(e) => {
                      // Allow only digits and limit to 4 characters
                      const newValue = e.target.value
                        .replace(/\D/g, "")
                        .slice(0, 4);
                      setOtp(newValue);
                    }}
                    maxLength={4} // Limit input length to 4 characters
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            )}

            <div>
              {!otpSent ? (
                <button
                  onClick={handleSendOtp}
                  disabled={loading}
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {loading ? "Sending..." : "Send OTP"}
                </button>
              ) : (
                <>
                  <button
                    onClick={handleVerifyOtp}
                    disabled={loading}
                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    {loading ? "Verifying..." : "Verify OTP"}
                  </button>
                  <div className="mt-4 text-center">
                    <p className="text-sm text-gray-600">
                      {canResend ? (
                        <button
                          onClick={handleResendOtp}
                          className="text-indigo-600 hover:text-indigo-500"
                        >
                          Resend OTP
                        </button>
                      ) : (
                        <span>
                          Resend OTP in{" "}
                          {Math.floor(timer / 60)
                            .toString()
                            .padStart(2, "0")}
                          :{(timer % 60).toString().padStart(2, "0")}
                        </span>
                      )}
                    </p>
                  </div>
                </>
              )}
              {error && <p className="text-red-600 mt-2">{error}</p>}
              {success && <p className="text-green-600 mt-2">{success}</p>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
