import { FaPaperclip } from "react-icons/fa6";
import TopBar from "./topbar";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Dialog, DialogContent } from "@mui/material";
import { BiSolidPencil } from "react-icons/bi";
import { toast, ToastContainer } from "react-toastify";
import { IoIosCloseCircleOutline } from "react-icons/io";
export default function UserProfile() {
  const [user, setUser] = useState();

  const navigate = useNavigate();
  const handleLogout = () => {
    // Implement your logout functionality here
    axios
      .get("https://byportecommerce-ufza.onrender.com/api/v1/logout")
      .then((res) => {
        console.log("Logging out...");
        // Example: Clear localStorage
        localStorage.removeItem("token");
        // Close the dropdown after logout
        // setShowDropdown(false);
        // Navigate to the home page or login page after logout
        navigate("/");
      });
  };
  const [editOpen, setEditOpen] = useState();
  const handleOpen = () => {
    setEditOpen(true);
  };

  const updateUser = () => {
    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", email);
    formData.append("mobileNumber", mobileNumber);

    formData.append("avatar", avatarPreview); // Append avatar file if it's updated

    axios
      .put(
        `https://byportecommerce-ufza.onrender.com/api/v1/me/update/${user?._id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        toast.success("Profile Updated Successfully");
        setEditOpen(false);
        navigate("/");
      })
      .catch((err) => {
        console.error("Error updating profile:", err);
      });
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [avatar, setAvatar] = useState(null); // State to store avatar file
  const [avatarPreview, setAvatarPreview] = useState(null); // State to preview avatar
  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAvatar(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatarPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    // Fetch user data on component mount
    const getOne = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `https://byportecommerce-ufza.onrender.com/api/v1/me`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUser(response.data.user);
        setFirstName(response.data.user.firstName);
        setLastName(response.data.user.lastName);
        setEmail(response.data.user.email);
        setMobileNumber(response.data.user.mobileNumber);
        setAvatarPreview(response.data.user.avatar.url);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    getOne();
  }, []);
  return (
    <>
      <ToastContainer />
      <TopBar />

      <div
        className="bg-white shadow-lg"
        style={{
          background: "white",
          marginLeft: "20px",
          marginRight: "20px",
          marginTop: "20px",
        }}
      >
        <div className="px-4 sm:px-0" style={{ margin: "1rem" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3 className="text-base font-semibold leading-7 text-gray-900">
              Applicant Information
            </h3>
            <div onClick={handleOpen}>
              <BiSolidPencil size={20} />
            </div>
          </div>
          <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
            Personal details and application.
          </p>
        </div>

        <div
          className="mt-6 border-t border-gray-100"
          style={{ margin: "1rem" }}
        >
          <dl className="divide-y divide-gray-100">
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Image
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                <img
                  src={user?.avatar.url}
                  alt="imh"
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "50px",
                  }}
                />
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Full name
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {user?.firstName} {user?.lastName}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Email
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {user?.email}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Phone Number
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {user?.mobileNumber}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <Dialog open={editOpen}>
        <DialogContent>
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "end",
              cursor: "pointer",
            }}
          >
            <IoIosCloseCircleOutline
              size={20}
              onClick={() => setEditOpen(false)}
            />
          </div>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <div className="space-y-6">
              <div>
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  First Name
                </label>
                <div className="mt-2">
                  <input
                    id="firstName"
                    name="firstName"
                    type="text"
                    autoComplete="given-name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Last Name
                </label>
                <div className="mt-2">
                  <input
                    id="lastName"
                    name="lastName"
                    type="text"
                    autoComplete="family-name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="mobileNumber"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Mobile Number
                </label>
                <div className="mt-2">
                  <input
                    id="mobileNumber"
                    name="mobileNumber"
                    type="tel"
                    autoComplete="tel"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="avatar"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Avatar
                </label>
                <div className="mt-2">
                  <input
                    id="avatar"
                    name="avatar"
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    onChange={handleAvatarChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                {avatarPreview && (
                  <img
                    src={avatarPreview}
                    alt="Avatar Preview"
                    className="mt-2 rounded-full h-24 w-24 mx-auto"
                  />
                )}
              </div>

              <div>
                <button
                  onClick={updateUser}
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
