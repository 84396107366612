import React, { useState } from "react";
import TopBar from "../component/topbar";
import DryFish from "../component/dryfish";
import CategoriesIcon from "../component/categoriesIcon";
import Footer from "../component/footer";
import UserProfile from "../component/userProfile";
import MobileFooter from "../component/mobileFooter";
import MasalaCard from "../component/masala";
import DryFruitsCard from "../component/dryfruits";
import HeroBanner from "../component/herobanner";
import ProductCard from "../component/productCard";
import AllProductCard from "../component/allProductCard";
import Categories from "../component/categories";

const DryFruitsPage = () => {
  const [activeTab, setActiveTab] = useState("menu");

  // Function to set active tab
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <TopBar />
      <div className="hidden md:block">
        {" "}
        {/* Show only on medium screens (tablets) and above */}
        <DryFruitsCard />
        <Footer />
      </div>
      <div className="md:hidden">
        {" "}
        {/* Hide on medium screens (tablets) and above */}
        <div className="flex justify-center mt-8">
          <button
            onClick={() => handleTabClick("home")}
            className={`rounded-tl-lg rounded-bl-lg px-6 py-2 focus:outline-none ${
              activeTab === "home"
                ? "bg-blue-500 text-white"
                : "bg-white text-blue-500"
            }`}
          >
            Home
          </button>
          <button
            onClick={() => handleTabClick("menu")}
            className={`rounded-tr-lg rounded-br-lg px-6 py-2 focus:outline-none ${
              activeTab === "menu"
                ? "bg-blue-500 text-white"
                : "bg-white text-blue-500"
            }`}
          >
            Menu
          </button>
        </div>
      </div>

      {/* Text displaying current active tab - show only on mobile and tablet */}

      {/* Conditional rendering based on activeTab */}
      <div className="container mx-auto px-4 mt-8 md:hidden">
        {activeTab === "menu" && (
          <>
            <CategoriesIcon />
            <DryFruitsCard />
            <MobileFooter />
          </>
        )}

        {activeTab === "home" && (
          <>
            <CategoriesIcon />
            <HeroBanner />
            <ProductCard />
            <AllProductCard />
            <Categories />
            <MobileFooter />
          </>
        )}
      </div>
    </div>
  );
};

export default DryFruitsPage;
