import React from "react";

const Privacy = () => {
  return (
    <div>
      <h3>Privacy Policy</h3>
      <div>
        We use Your Personal data to provide and improve the Service. By using
        the Service, You agree to the collection and use of information in
        accordance with this privacy policy.
      </div>
      <div>
        <div>Interpretation :-</div>
        <p>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions.
        </p>
        <p>
          The following definitions shall have the same meaning regardless of
          whether they appear in singular or in plural
        </p>
        <h5>Definitions :-</h5>
        For the purposes of this Privacy Policy:
        <ul>
          <li>
            “Account” means a unique account created for You to access our
            Service or parts of our Service.
          </li>
          <li>
            “Country” refers to INDIA. “Cookies” are small files that are placed
            on Your computer, mobile device or any other device by a website,
            containing the details of Your browsing history on that website
            among its many uses.
          </li>
          <li>
            Device” means any device that can access the Service such as a
            computer, a cell phone or a digital tablet
          </li>
          <li>
            “Personal Data” is any information that relates to an identified or
            identifiable individual.
          </li>
          <li>“Service” refers to the Website.</li>
          <li>
            “Service Provider” means any natural or legal person who processes
            the data on behalf of the Company. It refers to third-party
            companies or individuals employed by the Company to facilitate the
            Service, to provide the Service on behalf of the Company, to perform
            services related to the Service or to assist the Company in
            analyzing how the Service is used.
          </li>
          <li>
            “Usage Data” refers to data collected automatically, either
            generated by the use of the Service or from the Service
            infrastructure itself.
          </li>
          <li>
            “Website” refers to BYPORT, accessible from https://byport.co/
          </li>
          <li>
            “You” means the individual accessing or using the Service, or the
            company, or other legal entity on behalf of which such individual is
            accessing or using the Service, as applicable.
          </li>
        </ul>
      </div>
      <div>
        <h4>Retention of Your Personal Data :-</h4>
        <p>
          The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies. The Company will also
          retain Usage Data for internal analysis purposes. Usage Data is
          generally retained for a shorter period of time, except when this data
          is used to strengthen the security or to improve the Functionality of
          Our Service, or We are legally obligated to retain this data for
          longer time periods.
        </p>
      </div>
      <div>
        <h4>Security of Your Personal Data</h4>
        <p>
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          Acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </p>
      </div>
      <div>
        <h4>Detailed Information on the Processing of Your Personal Data</h4>
        <p>
          The Service Providers We use may have access to Your Personal Data.
          These third-party vendors collect, store, use, process and transfer
          information about Your activity on Our Service in accordance with
          their Privacy Policies.
        </p>
      </div>
      <div>
        <h4>Payments </h4>
        <p>
          We may provide paid products and/or services within the Service. In
          that case, we may use third-party services for payment processing
          (e.g. payment processors). We will not store or collect Your payment
          card details. That information is provided directly to Our third-party
          payment processors whose use of Your personal information is governed
          by their Privacy Policy. These payment processors adhere to the
          standards set by PCI-DSS as managed by the PCI Security Standards
          Council, which is a joint effort of brands like Visa, Mastercard, and
          Discover. PCI-DSS Requirements help ensure the secure handling of
          payment information.
        </p>
      </div>
      <div>
        <h4>Other legal requirements</h4>
        <p>
          The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
        </p>
        <p>Comply with a legal obligation</p>
        <p> Protect and defend the rights or property of the Company</p>
        <p>
          Prevent or investigate possible wrongdoing in connection with the
          Service.
        </p>
      </div>
    </div>
  );
};

export default Privacy;
