import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import './hero.css';  // Import the CSS file

const HeroBanner = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768, // Tablet and below
        settings: {
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480, // Mobile phones
        settings: {
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  const [banners, setBanners] = useState([]);
  
  const getAllBanners = () => {
    axios
      .get("https://byportecommerce-ufza.onrender.com/api/v1/admin/get-all-banner")
      .then((res) => {
        setBanners(res.data.products || []);  // Adjust based on actual API response.
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getAllBanners();
  }, []);

  return (
    <div className="hero-banner-container">
      <Slider {...settings}>
        {banners.length > 0 ? (
          banners.map((banner, index) => (
            <div key={index} className="relative">
              <img
                className="hero-banner-image"
                alt={`banner-${index}`}
                src={banner.avatar.url}  // Adjust based on actual API response.
              />
              {banner.discount && (
                <div className="banner-discount">
                  {banner.discount} %
                </div>
              )}
              {banner.title && (
                <div className="banner-title">
                  <h2 className="text-3xl font-bold">
                    {banner.title}
                  </h2>
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="flex items-center justify-center h-96 text-center text-xl text-gray-600">
            No data found
          </div>
        )}
      </Slider>
    </div>
  );
};

export default HeroBanner;
