import React from "react";
import TopBar from "../component/topbar";
import CategoriesIcon from "../component/categoriesIcon";
import OrderHistory from "../component/orderhistory";

const OrderHistoryPage = () => {
  return (
    <div>
      <TopBar />
      <CategoriesIcon />
      <OrderHistory />
    </div>
  );
};

export default OrderHistoryPage;
