import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import groc from "../../src/images/grocer.jpeg";
import sweet from "../../src/images/sweet.png";

import fruit from "../../src/images/dry.jpeg";
import masala from "../../src/images/spices.jpeg";
import fish from "../../src/images/dryfish.png";

const Categories = () => {
  const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <button
        className={`${className} bg-black-200 text-gray-600 rounded-full w-10 h-10 flex items-center justify-center`}
        style={{ ...style, right: "-30px", background: "black" }}
        onClick={onClick}
      >
        &lt;
      </button>
    );
  };

  const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <button
        className={`${className} bg-black-200 text-gray-600 rounded-full w-10 h-10 flex items-center justify-center`}
        style={{ ...style, right: "-30px", background: "black" }}
        onClick={onClick}
      >
        &gt;
      </button>
    );
  };
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-20">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
            Our Categories
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            Explore our diverse categories: Groceries for essential household
            staples and cooking ingredients, Masala for aromatic spice blends
            and individual spices, Sweet for an assortment of confections and
            desserts, Dry Fish for preserved seafood with rich flavors, and Dry
            Fruits for nutrient-packed snacks like almonds, cashews, and dates.
          </p>
        </div>
        <Slider {...settings}>
          <div className="p-2">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img
                alt="team"
                className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
                src={groc}
              />
              <div className="flex-grow">
                <h2 className="text-gray-900 title-font font-medium">
                  Groceries
                </h2>
                {/* <p className="text-gray-500">UI Designer</p> */}
              </div>
            </div>
          </div>
          <div className="p-2">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img
                alt="team"
                className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
                src={fruit}
              />
              <div className="flex-grow">
                <h2 className="text-gray-900 title-font font-medium">
                  Dry Fruits
                </h2>
                {/* <p className="text-gray-500">UI Designer</p> */}
              </div>
            </div>
          </div>
          <div className="p-2">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img
                alt="team"
                className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
                src={fish}
              />
              <div className="flex-grow">
                <h2 className="text-gray-900 title-font font-medium">
                  Dry Fish
                </h2>
                {/* <p className="text-gray-500">UI Designer</p> */}
              </div>
            </div>
          </div>
          <div className="p-2">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img
                alt="team"
                className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
                src={masala}
              />
              <div className="flex-grow">
                <h2 className="text-gray-900 title-font font-medium">Masala</h2>
                {/* <p className="text-gray-500">UI Designer</p> */}
              </div>
            </div>
          </div>
          <div className="p-2">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img
                alt="team"
                className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
                src={sweet}
              />
              <div className="flex-grow">
                <h2 className="text-gray-900 title-font font-medium">Sweet</h2>
              </div>
            </div>
          </div>
          {/* Repeat the above structure for each item */}
        </Slider>
      </div>
    </section>
  );
};

export default Categories;
