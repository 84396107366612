import React, { useEffect, useState } from "react";
import AdminHeader from "./adminHeader";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import AddCategories from "./addCategories";
import axios from "axios";
import { Link } from "react-router-dom";
import EditCategories from "./editCategories";
import { DataGrid } from "@mui/x-data-grid"; // Import DataGrid
import EditBanner from "./editBanner";
import AddBanner from "./addBanner";
import { toast, ToastContainer } from "react-toastify";

const BannerList = () => {
  const [form, setForm] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const [categories, setCategory] = useState([]);
  const [categoryId, setCategoryId] = useState();
  const fetClose = () => {
    setForm(false);
    getAllCatgeories();
  };
  const fetsClose = () => {
    setOpenEdit(false);
    getAllCatgeories();
  };
  const getAllCatgeories = () => {
    axios
      .get(
        "https://byportecommerce-ufza.onrender.com/api/v1/admin/get-all-banner"
      )
      .then((res) => {
        setCategory(res.data.products);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getAllCatgeories();
  }, []);
  const handleDelete = (id) => {
    axios
      .delete(
        `https://byportecommerce-ufza.onrender.com/api/v1/admin/get-delete-banner/${id}`
      )
      .then((res) => {
        toast.success("Banner Deleted Successfully");
        getAllCatgeories()
      });
  };
  const columns = [
    {
      field: "avatar",
      headerName: "Image",
      width: 100,
      renderCell: (params) => (
        <img
          src={params.row.avatar.url}
          alt="Category"
          style={{ width: "80px", height: "auto", borderRadius: "4px" }}
        />
      ),
    },
    { field: "title", headerName: "Title", width: 200 },
    { field: "discount", headerName: "Discount", width: 150 },
    {
      field: "action",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <Link
            className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
            onClick={() => {
              setCategoryId(params.row._id);
              setOpenEdit(true);
            }}
          >
            Edit
          </Link>
          <button
            className="font-medium text-red-600 dark:text-red-500 hover:underline"
            onClick={() => handleDelete(params.row._id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <AdminHeader />
      <ToastContainer />
      <div
        class="relative overflow-x-auto shadow-md sm:rounded-lg"
        style={{ marginLeft: "20px", marginRight: "20px", marginTop: "5rem" }}
      >
        <button
          class="flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded"
          onClick={() => setForm(true)}
        >
          Add Banner
        </button>
        <div style={{ height: 400, width: "100%", margin: "2rem" }}>
          <DataGrid
            rows={categories}
            columns={columns}
            pagination
            getRowId={(row) => row._id}
          />
        </div>
      </div>
      <Dialog
        className="relative z-10"
        open={form}
        onClose={() => setForm(false)}
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex  items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <AddBanner onClose={fetClose} />
            </DialogPanel>
          </div>
        </div>
      </Dialog>
      <Dialog
        className="relative z-10"
        open={openEdit}
        onClose={() => setOpenEdit(false)}
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex  items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <EditBanner onClose={fetsClose} id={categoryId} />
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default BannerList;
