import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer
      className="text-gray-600 body-font hidden md:block" // Hide on mobile and tablet, show on desktop
      style={{ boxShadow: "12px -3px 7px #d4d5d4" }}
    >
      <div className="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
        <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left md:mt-0 mt-10">
          <Link className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
            <Link to={"/"} className="-m-1.5 p-1.5">
              <span className="sr-only">By Port</span>
              <img
                className="h-8 w-auto"
                src="https://res.cloudinary.com/dq9pir6ve/image/upload/v1720260165/images/wqga0vcdsrmaeoehhkev.png"
                alt=""
              />
            </Link>
          </Link>
          <p className="mt-2 text-sm text-gray-500">
            Everything at your finger tip empowering your shopping experience
            one click at time
          </p>
        </div>
        <div className="flex-grow flex flex-wrap md:pr-20 -mb-10 md:text-left text-center order-first">
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
              CATEGORIES
            </h2>
            <nav className="list-none mb-10">
              <li>
                <Link
                  to={"/groceries"}
                  className="text-gray-600 hover:text-gray-800"
                >
                  Groceries
                </Link>
              </li>
              <li>
                <Link
                  to={"/dry-fruits"}
                  className="text-gray-600 hover:text-gray-800"
                >
                  Dry Fruits
                </Link>
              </li>
              <li>
                <Link
                  to={"/dry-fish"}
                  className="text-gray-600 hover:text-gray-800"
                >
                  Dry Fish
                </Link>
              </li>
              <li>
                <Link
                  to={"/masala"}
                  className="text-gray-600 hover:text-gray-800"
                >
                  Masala
                </Link>
              </li>
              <li>
                <Link
                  to={"/sweet"}
                  className="text-gray-600 hover:text-gray-800"
                >
                  Sweet
                </Link>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
              Our Policy
            </h2>
            <nav className="list-none mb-10">
              <li>
                <Link
                  to={"/term"}
                  className="text-gray-600 hover:text-gray-800"
                >
                  Terms and Conditions
                </Link>
              </li>
              <li>
                <Link
                  to={"/privacy"}
                  className="text-gray-600 hover:text-gray-800"
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  to={"/refund"}
                  className="text-gray-600 hover:text-gray-800"
                >
                  Refund Policy
                </Link>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
              Address
            </h2>
            <p className="mt-2 text-sm text-gray-500">
              79/19A Aaliyar Street Kayalpatnam Tuticorin District Tamil Nadu
              -628204
            </p>
            <p className="mt-2 text-sm text-gray-500">
              Call Us: +91 81241 16395 || +91 91591 95829
            </p>
            <p className="mt-2 text-sm text-gray-500">
              Email Us: Byportecommerce@Gmail.Com
            </p>
          </div>
          {/* Additional sections omitted for brevity */}
        </div>
      </div>
      <div className="bg-gray-100">
        <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
          <p className="text-gray-500 text-sm text-center sm:text-left">
            © 2023 ByPort —
            <Link
              href="https://twitter.com/knyttneve"
              rel="noopener noreferrer"
              className="text-gray-600 ml-1"
              target="_blank"
            >
              @byport
            </Link>
          </p>
          <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
            <Link className="text-gray-500">
              <svg
                fill="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
              </svg>
            </Link>
            {/* Additional social icons omitted for brevity */}
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
