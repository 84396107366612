import axios from "axios";
import React, { useEffect, useState } from "react";

const OrderHistory = () => {
  const [user, setUser] = useState();
  const [orders, setOrders] = useState([]);
  console.log(orders, "orders");
  useEffect(() => {
    // Fetch user data on component mount
    const getOne = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`https://byportecommerce-ufza.onrender.com/api/v1/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data.user);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    getOne();
  }, []);
  const getOneOrder = () => {
    const token = localStorage.getItem("token");
    const payload = {
      user: user?._id,
    };
    axios
      .get(`https://byportecommerce-ufza.onrender.com/api/v1/user/one-order/${user?._id}`)
      .then((res) => {
        setOrders(res.data.orders);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getOneOrder();
  }, []);
  function formatDateWithTime(dateString) {
    // Function to get the ordinal suffix for the day of the month
    function getOrdinalSuffix(day) {
      if (day >= 11 && day <= 13) {
        return "th";
      }
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    // Function to get the full month name
    function getMonthName(month) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return months[month];
    }

    // Function to get the day of the week name
    function getDayName(day) {
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      return days[day];
    }

    // Function to format the date
    function formatDateString(dateString) {
      const date = new Date(dateString);
      const day = date.getDate();
      const monthName = getMonthName(date.getMonth());
      const dayOfWeek = getDayName(date.getDay());
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = date.getMinutes();

      const ordinalSuffix = getOrdinalSuffix(day);

      // Format the time
      const formattedTime = `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;

      // Construct the final formatted date string
      const formattedDateString = `${day}${ordinalSuffix} ${monthName}, ${dayOfWeek} ${year} at ${formattedTime}`;

      return formattedDateString;
    }

    // Call the formatDateString function and return its result
    return formatDateString(dateString);
  }

  // Usage example
  const calculateExpectedDeliveryDate = (createdAt) => {
    const date = new Date(createdAt);
    date.setDate(date.getDate() + 7); // Add 7 days
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };
  return (
    <div>
      <section class="py-24 relative">
        <div class="w-full max-w-7xl px-4 md:px-5 lg-6 mx-auto">
          <h2 class="font-manrope font-bold text-4xl leading-10 text-black text-center">
            Order History
          </h2>
          {orders.map((order) => {
            return (
              <div class="main-box border border-gray-200 rounded-xl pt-6 max-w-xl max-lg:mx-auto lg:max-w-full">
                <div class="flex flex-col lg:flex-row lg:items-center justify-between px-6 pb-6 border-b border-gray-200">
                  <div class="data">
                    <p class="font-semibold text-base leading-7 text-black">
                      Order Id:{" "}
                      <span class="text-indigo-600 font-medium">
                        {order?.uniqueOrderId}
                      </span>
                    </p>
                    <p class="font-semibold text-base leading-7 text-black mt-4">
                      Order Payment :{" "}
                      <span class="text-gray-400 font-medium">
                        {" "}
                        {formatDateWithTime(order?.createdAt)}
                      </span>
                    </p>
                  </div>
                </div>
                <div class="w-full px-3 min-[400px]:px-6">
                  <div class="flex flex-col lg:flex-row items-center py-6 border-b border-gray-200 gap-6 w-full">
                    {order?.orderItems.map((a, i) => {
                      return (
                        <>
                          <div class="img-box max-lg:w-full">
                        <img
                          src={a.image}
                          alt="Premium Watch"
                          class="aspect-square w-full lg:max-w-[140px]"
                        />
                      </div>
                          <div class="flex flex-row items-center w-full ">
                            <div class="grid grid-cols-1 lg:grid-cols-2 w-full">
                              <div class="flex items-center">
                                <div class="">
                                  <h2 class="font-semibold text-xl leading-8 text-black mb-3">
                                    {a.name}
                                  </h2>

                                  <div class="flex items-center ">
                                    <p class="font-medium text-base leading-7 text-black pr-4 mr-4 border-r border-gray-200">
                                      Size:{" "}
                                      <span class="text-gray-500">
                                        {a.size}
                                      </span>
                                    </p>
                                    <p class="font-medium text-base leading-7 text-black ">
                                      Qty:{" "}
                                      <span class="text-gray-500">
                                        {a.quantity}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="grid grid-cols-5">
                                <div class="col-span-5 lg:col-span-1 flex items-center max-lg:mt-3">
                                  <div class="flex gap-3 lg:block">
                                    <p class="font-medium text-sm leading-7 text-black">
                                      price
                                    </p>
                                    <p class="lg:mt-4 font-medium text-sm leading-7 text-indigo-600">
                                      {order?.totalPrice}
                                    </p>
                                  </div>
                                </div>
                                <div class="col-span-5 lg:col-span-2 flex items-center max-lg:mt-3 ">
                                  <div class="flex gap-3 lg:block">
                                    <p class="font-medium text-sm leading-7 text-black">
                                      Status
                                    </p>
                                    <p class="font-medium text-sm leading-6 whitespace-nowrap py-0.5 px-3 rounded-full lg:mt-3 bg-emerald-50 text-emerald-600">
                                      {order?.orderStatus}
                                    </p>
                                  </div>
                                </div>
                                <div class="col-span-5 lg:col-span-2 flex items-center max-lg:mt-3">
                                  <div class="flex gap-3 lg:block">
                                    <p class="font-medium text-sm whitespace-nowrap leading-6 text-black">
                                      Expected Delivery Time
                                    </p>
                                    <p class="font-medium text-base whitespace-nowrap leading-7 lg:mt-3 text-emerald-500">
                                      {calculateExpectedDeliveryDate(
                                        order.createdAt
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
                <div class="w-full border-t border-gray-200 px-6 flex flex-col lg:flex-row items-center justify-between ">
                  <p class="font-semibold text-lg text-black py-6">
                    Total Price:{" "}
                    <span class="text-indigo-600">{order?.totalPrice}</span>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default OrderHistory;
