import React from "react";
import TopBar from "../component/topbar";
import CategoriesIcon from "../component/categoriesIcon";
import OrderDetail from "../component/AdminPortal/orderdetail";
import Admin from "./Admin";

const OrderDetailPage = () => {
  return (
    <div>
      <Admin />
      <OrderDetail />
    </div>
  );
};

export default OrderDetailPage;
