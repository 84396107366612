import React from "react";

const Terms = () => {
  return (
    <div>
      <h3>Terms and Conditions Agreement Byport</h3>
      <div>
        <div>1. RIGHT TO MAKE CHANGES TO THE AGREEMENT :</div>
        <p>
          Changes to These Terms and Conditions As we reserve the right, at our
          sole discretion, to modify or replace these Terms at any time. If
          arevision is Material We will make reasonable efforts to provide at
          least 30 days’ notice prior to any new termstaking Effect. What
          constitutes a material change will be determined at Our sole
          discretion.By continuing to access or use Our Service after those
          revisions become effective, You agree to be bound
        </p>
      </div>
      <div>
        <div>2. COPYRIGHT INFRINGEMENTS & INTELLECTUAL PROPERTY :</div>
        <p>
          We respect the intellectual property rights of others. If you believe
          that any material available on orthrough the Site infringes upon any
          copyright you own or control, please immediately notify us usingthe
          contact information provided below (a “Notification”). A copy of your
          Notification will be sent tothe person who posted or stored the
          material addressed in the Notification.
        </p>
        <p>
          The Content and the Marks are provided on the Site “WWW.BYPORT.CO” for
          your information and personal use only. Except as expressly provided
          in these Terms and Conditions, no part of the Site and no Content or
          Marks may be copied, reproduced, aggregated, republished, uploaded,
          posted, publicly displayed, encoded, translated, transmitted,
          distributed, sold, licensed, or otherwise exploited for any commercial
          purpose whatever it is, without our express prior written
          permission.We reserve all rights not expressly granted to you in and
          to the Site, the Content and the Marks.
        </p>
      </div>
      <div>
        <div>3. GOVERNING LAW :</div>
        <p>
          These Terms and Conditions and your use of the Site are governed by
          and construed in accordancewith the laws of the State of Tamilnadu
          applicable to agreements made and to be entirely performed within the
          State/Commonwealth of Tamilnadu, without regard to its conflict of
          lawprinciples The laws of the Country, excluding its conflicts of law
          rules, shall govern this Terms and Your use ofthe Service.Your use of
          the Application may also be subject to other local, state, national,
          or international laws.
        </p>
      </div>
      <div>
        <div>4. PLACING ORDERS FOR PRODUCT :</div>
        <p>
          Placing Orders for Products By placing an Order for Products through
          the Service, You warrant that You are legally capable of entering into
          binding contracts. Your Information If You wish to place an Order for
          Products available on the Service, You may be asked to supply certain
          information relevantto Your Order including, without limitation, Your
          name, Your email, Your phone number, Your creditcard number, the
          expiration date of Your credit card, Your billing address, and Your
          shipping Information. You represent and warrant that:
        </p>
        <ul>
          <li>
            You have the legal right to use any credit or debit card(s) or other
            Payment method(s) in connection with any Order; and that
          </li>
          <li>The information You supply to us is true,</li>
          <li>
            Correct and complete.By submitting such information, You grant usthe
            right to provide the information to payment processing third parties
            for purposes of facilitating the completion of YourOrder.
          </li>
        </ul>
        <h4>Order Cancellation:</h4>
        <p>
          We reserve the right to refuse or cancel Your Order at any time for
          certain reasons including but notlimited To:
        </p>
        <ul>
          <li>Products availability</li>
          <li>Errors in the description or prices for Products</li>
          <li>Errors in Your Order</li>
        </ul>
        <p>
          We reserve the right to refuse or cancel Your Order if fraud or an
          unauthorized or illegal transactionis suspected. Your Order
          Cancellation Rights Any Products you purchase can only be returned or
          refunded in accordance with these Terms and Conditions. Availability,
          Errors and Inaccuracies. We are constantly updating Our offerings of
          Products on the Service. The Products available on OurService may be
          mispriced, described inaccurately, or unavailable, and We cannot and
          do not guarantee the accuracy or completeness of any information,
          including prices,product images, specifications, availability, and
          services. We reserve the right to change or update information and to
          correct errors, inaccuracies, or omissions at any time without prior
          notice.
        </p>
      </div>
      <div>
        <div>5. PRICING AND PAYMENT TERMS :</div>
        <p>
          Product prices are almost always subject to change, and your right to
          make these changes should be established in a dedicated Pricing or
          Payments clause. Other details of customer transactions, such
          asshipping, returns, refunds and discounts also should be addressed in
          this clause in order to give you the legal rights to manage these
          matters in the interest of your online store.
        </p>
        <div>CONTACT US</div>
        <p>
          In order to resolve a complaint/query regarding the Site or to receive
          any further information regarding use of the Site, please contact us
          at:
        </p>
        <diiv>
          <p>NAME: MOHIDEEN ABUBACKER ALMAJEED ASHIK IBRAHIM</p>
          <p>ADDRESS: 79/19 A ALIAR STREET KAYALPATNAM</p>
          <p>MOBILE: 9159195829</p>
          <p>EMAIL: sajjadanver3925@gmail.com</p>
        </diiv>
      </div>
    </div>
  );
};

export default Terms;
