import React, { useEffect, useState } from "react";
import AdminHeader from "./adminHeader";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import AddProduct from "./addproduct";
import axios from "axios";
import EditProduct from "./editProduct";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { FaEye } from "react-icons/fa6";
import EditOrder from "./editOrder";
import { DataGrid } from "@mui/x-data-grid";
const OrderAdminList = () => {
  const [form, setForm] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const [product, setProduct] = useState([]);
  const [categoryId, setCategoryId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(10); // Number of products per page
  const [totalProducts, setTotalProducts] = useState(0);
  const fetClose = () => {
    setForm(false);
    getAllCatgeories();
  };
  const fetsClose = () => {
    setOpenEdit(false);
    getAllCatgeories();
  };
  const getAllCatgeories = () => {
    axios
      .get("https://byportecommerce-ufza.onrender.com/api/v1/admin/orders", {})
      .then((res) => {
        setProduct(res.data.orders);
        setTotalProducts(res.data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getAllCatgeories();
  }, [currentPage, productsPerPage]);
  const getDeleteProduct = (id) => {
    axios
      .delete(
        `https://byportecommerce-ufza.onrender.com/api/v1/admin/product/${id}`
      )
      .then((res) => {
        toast.success("Product Deleted Successfully");
      });
  };
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate total number of pages
  const totalPages = Math.ceil(totalProducts / productsPerPage);
  const navigate = useNavigate();
  const handlePaymentLink = () => {
    navigate(`/admin-order-list`);
  };
  const handleEditClick = (id) => {
    setCategoryId(id);
    setOpenEdit(true);
  };
  const columns = [
    { field: "productName", headerName: "Product Name", flex: 1 },
    { field: "orderId", headerName: "Order Id", flex: 1 },
    { field: "price", headerName: "Price", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <span style={{ color: getStatusColor(params.value) }}>
          {params.value}
        </span>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <>
          <Link
            className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
            onClick={() => handleEditClick(params.row._id)}
          >
            Edit
          </Link>
          <div style={{ cursor: "pointer" }}>
            <Link to={`/order-detail/${params.row._id}`}>
              <FaEye size={30} />
            </Link>
          </div>
        </>
      ),
    },
  ];

  const getStatusColor = (status) => {
    switch (status) {
      case "Processing":
        return "red";
      case "Delivered":
        return "green";
      case "Shipped":
        return "yellow";
      default:
        return "black";
    }
  };

  return (
    <div>
      <AdminHeader />
      <ToastContainer />
      <div style={{ height: 400, width: "75%",margin:"2rem" }}>
        <DataGrid
          getRowId={(row) => row._id}
          columns={columns}
          pageSize={productsPerPage}
          pagination
          rowCount={totalProducts}
          onPageChange={(newPage) => setCurrentPage(newPage)}
        />
      </div>

      <Dialog
        className="relative z-10"
        open={openEdit}
        onClose={() => setOpenEdit(false)}
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <EditOrder onClose={fetsClose} id={categoryId} />
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default OrderAdminList;
