import React, { useEffect, useState } from "react";
import AdminHeader from "./adminHeader";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import AddProduct from "./addproduct";
import axios from "axios";
import EditProduct from "./editProduct";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { DataGrid } from "@mui/x-data-grid"; // Import DataGrid

const ProductAdminLits = () => {
  const [form, setForm] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const [product, setProduct] = useState([]);
  const [categoryId, setCategoryId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 10; // Number of products per page
  const [totalProducts, setTotalProducts] = useState(0);

  const fetClose = () => {
    setForm(false);
    getAllCatgeories(currentPage);
  };

  const fetsClose = () => {
    setOpenEdit(false);
    getAllCatgeories(currentPage);
  };

  const getAllCatgeories = () => {
    const token = localStorage.getItem("token");

    axios
      .get(
        "https://byportecommerce-ufza.onrender.com/api/v1/products",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setProduct(res.data.products);
        setTotalProducts(res.data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllCatgeories(currentPage);
  }, [currentPage, productsPerPage]);

  const getDeleteProduct = (id) => {
    axios
      .delete(
        `https://byportecommerce-ufza.onrender.com/api/v1/admin/product/${id}`
      )
      .then((res) => {
        toast.success("Product Deleted Successfully");
      });
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate total number of pages
  const totalPages = Math.ceil(totalProducts / productsPerPage);

  // Columns configuration for DataGrid
  const columns = [
    {
      field: "images",
      headerName: "Image",
      width: 120,
      renderCell: (params) => (
        <img
          src={params.row.images[0].url}
          alt="Product"
          style={{ width: "50px", height: "50px", objectFit: "cover" }}
        />
      ),
    },
    { field: "name", headerName: "Product name", width: 200 },
    { field: "category", headerName: "Category", width: 150 },
    { field: "price", headerName: "Price", width: 120 },
    {
      field: "actions",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <div>
          <Link
            className="font-medium text-blue-600 dark:text-blue-500 hover:underline cursor-pointer"
            onClick={() => {
              setCategoryId(params.row._id);
              setOpenEdit(true);
            }}
          >
            Edit
          </Link>
          <span className="mx-2">|</span>
          <Link
            className="font-medium text-blue-600 dark:text-blue-500 hover:underline cursor-pointer"
            onClick={() => getDeleteProduct(params.row._id)}
          >
            Delete
          </Link>
        </div>
      ),
    },
  ];

  return (
    <div>
      <AdminHeader />
      <ToastContainer />
      <div
        className="relative overflow-x-auto shadow-md sm:rounded-lg"
        style={{ marginLeft: "20px", marginRight: "20px", marginTop: "5rem" }}
      >
        <button
          className="flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded"
          onClick={() => setForm(true)}
        >
          Add Product
        </button>

        <div style={{ height: 400, width: "100%", marginTop: "2px" }}>
          <DataGrid
            rows={product}
            columns={columns}
            pageSize={productsPerPage}
            page={currentPage - 1} // DataGrid page is 0-indexed
            onPageChange={(newPage) => paginate(newPage + 1)} // Convert to 1-indexed for your pagination
            pagination
            getRowId={(row) => row._id}
          />
        </div>
      </div>

      <Dialog
        className="relative z-10"
        open={form}
        onClose={() => setForm(false)}
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex  items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <AddProduct onClose={fetClose} />
            </DialogPanel>
          </div>
        </div>
      </Dialog>

      <Dialog
        className="relative z-10"
        open={openEdit}
        onClose={() => setOpenEdit(false)}
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex  items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <EditProduct onClose={fetsClose} id={categoryId} />
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ProductAdminLits;
