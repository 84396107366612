import React, { useEffect, useState } from "react";
import { FaUser, FaShoppingCart } from "react-icons/fa";
import { CiSettings } from "react-icons/ci";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { BiLogIn } from "react-icons/bi";
import { RiLogoutCircleLine } from "react-icons/ri";

const MobileFooter = () => {
  const navigate = useNavigate();
  const [cartItemsCount, setCartItemsCount] = useState(0); // State to hold cart items count
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Fetch cart items from local storage and calculate total items count
    const cartItems = JSON.parse(localStorage.getItem("cart")) || [];
    const totalCount = cartItems.reduce((acc, item) => acc + item.quantity, 0);
    setCartItemsCount(totalCount);
  }, []);

  useEffect(() => {
    // Fetch user data if token exists
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const response = await axios.get(
            `https://byportecommerce-ufza.onrender.com/api/v1/me`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setUser(response.data.user);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserData();
  }, []);

  // Function to check if the device is mobile or tablet
  const isMobileOrTablet = () => {
    const screenWidth = window.innerWidth;
    return screenWidth < 1024; // Adjust this breakpoint as needed
  };

  // Render MobileFooter only on mobile and tablet devices
  if (!isMobileOrTablet()) {
    return null; // Return null if on desktop
  }

  const handleLogout = () => {
    // Implement your logout functionality here
    axios
      .get("https://byportecommerce-ufza.onrender.com/api/v1/logout")
      .then(() => {
        console.log("Logging out...");
        // Example: Clear localStorage
        localStorage.removeItem("token");
        // Navigate to the home page or login page after logout
        navigate("/");
      });
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white shadow-lg p-4 flex justify-around items-center rounded-t-xl">
      {/* Conditional rendering based on user authentication */}
      {user && localStorage.getItem("token") ? (
        <>
          {/* User avatar */}
          {user.roles === "admin" ? (
            <Link to={`/dashboard`}>
              <img
                src={user.avatar.url}
                alt="User Avatar"
                style={{
                  border: "1px solid grey",
                  borderRadius: "50px",
                }}
                className="w-10 h-10 rounded-full cursor-pointer hover:opacity-80 transition-opacity duration-300"
              />
            </Link>
          ) : user.roles === "user" ? (
            <Link>
              <img
                src={user.avatar.url}
                alt="User Avatar"
                style={{
                  border: "1px solid grey",
                  borderRadius: "50px",
                }}
                className="w-10 h-10 rounded-full cursor-pointer hover:opacity-80 transition-opacity duration-300"
              />
            </Link>
          ) : (
            <FaUser className="text-gray-600 text-xl" />
          )}
        </>
      ) : (
        // If user is not authenticated, show FaUser icon
        <FaUser className="text-gray-600 text-xl" />
      )}

      {/* Cart icon with items count */}
      <div className="relative">
        <FaShoppingCart
          size={20}
          className="text-gray-600 text-xl cursor-pointer"
          onClick={() => navigate("/cart")}
        />
        {cartItemsCount > 0 && (
          <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
            {cartItemsCount}
          </span>
        )}
      </div>

      {/* Settings icon */}
      {user && localStorage.getItem("token") ? (
        <>
          <RiLogoutCircleLine
            className="text-red-600 text-xl cursor-pointer"
            onClick={handleLogout}
          />
        </>
      ) : (
        <>
          <BiLogIn
            className="text-gray-600 text-xl cursor-pointer"
            onClick={() => navigate("/login")}
          />
        </>
      )}
    </div>
  );
};

export default MobileFooter;
