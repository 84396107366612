import React from "react";

const Refund = () => {
  return (
    <div>
      <h3>Refund and Shipping Policy</h3>
      <p>
        At ByPort, we strive to provide exceptional products and service to our
        customers. To ensure transparency and clarity, please review our refund
        and shipping policy outlined below:-
      </p>
      <div style={{ marginTop: "20px" }}>
        <div>
          1. Refund Eligibility: We offer refunds within 2 days of purchase. To
          be eligible, the item must be unused and in the same condition as
          received. Any item not in its original condition or damaged due to
          misuse will not be eligible for a refund.
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <div>
          2. Refund Process: To initiate a refund, please contact our customer
          support team at contact email/number mentioned at the below with your
          order details. Our team will guide you through the process and provide
          further instructions.
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <div>
          3. Return Shipping: Customers are responsible for return shipping
          costs unless the item received was damaged or defective. In such
          cases, we will provide a prepaid shipping label for return.
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <div>
          4. Refund Timeframe: Once your return is received and inspected, we
          will notify you of the approval or rejection of your refund. Approved
          refunds will be processed within 7 business days and credited to the
          original method of payment.
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <div>
          5. Exchange Policy: We currently do not offer direct exchanges. If you
          wish to exchange an item, please follow the refund process and place a
          new order for the desired item.
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <div>
          6. Shipping Methods: We offer several shipping options to accommodate
          your needs. Standard shipping typically takes 6 working days, while
          expedited shipping options are available for faster delivery
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <div>
          7. Shipping Rates: Shipping rates are calculated based on the weight
          and destination of your order. You can view the shipping cost at
          checkout before completing your purchase.
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <div>
          8. International Shipping: We ship internationally to select
          countries. Please note that additional customs duties and taxes may
          apply, which are the responsibility of the recipient
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <div>
          9. Order Tracking: Once your order is processed and shipped, you will
          receive a tracking number via email to monitor the status of your
          delivery.
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <div>
          10. Delivery Guarantee: While we strive to deliver your order in a
          timely manner, please note that shipping times may vary due to factors
          beyond our control, such as weather conditions or carrier delays.
          However, we are committed to resolving any issues promptly to ensure
          your satisfaction.
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <h3>
          For any further inquiries or assistance, please don't hesitate to
          contact our customer support team.
        </h3>
        <p>ByPort Assistance Team</p>
        <div>Contact Number :-</div>
        <p> +91-8124116395 :- Mr. Mohudoom</p>
        <p>+91-9159195829 :- Mr. Ashik </p>
      </div>
    </div>
  );
};

export default Refund;
